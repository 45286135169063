import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@store/index';

const getCustomerState = (state: RootState) => state.customer;

/**
 * Selectors
 */
export const selectCategoryId = (state: RootState) => getCustomerState(state).categoryId;
export const selectApplicationItems = (state: RootState) => getCustomerState(state).applicationItems;
export const selectActiveTab = (state: RootState) => getCustomerState(state).tab;
export const selectModal = (state: RootState) => getCustomerState(state).modal;
export const selectSupermarket = (state: RootState) => getCustomerState(state).supermarket;
export const selectPharmacy = (state: RootState) => getCustomerState(state).pharmacy;
export const selectGasStation = (state: RootState) => getCustomerState(state).gasStation;
export const selectEasterShop = (state: RootState) => getCustomerState(state).easterShop;
export const selectChildProtectionShop = (state: RootState) => getCustomerState(state).childProtectedShop;
export const selectChildrenToysShop = (state: RootState) => getCustomerState(state).childrenToysShop;
export const selectSupermarketRules = (state: RootState) => getCustomerState(state).supermarketRules;
export const selectPharmacyRules = (state: RootState) => getCustomerState(state).pharmacyRules;
export const selectGasStationRules = (state: RootState) => getCustomerState(state).gasStationRules;
export const selectEasterRules = (state: RootState) => getCustomerState(state).easterRules;
export const selectChildProtectionRules = (state: RootState) => getCustomerState(state).childProtectedRules;
export const selectChildrenToysRules = (state: RootState) => getCustomerState(state).childrenToysRules;
export const selectApplicationItemsCategories = createSelector(selectApplicationItems, (applicationItems) => {
  const categoriesSet = new Set();
  applicationItems.forEach((item) => {
    const categoryName = item.category.name;
    categoriesSet.add(categoryName);
  });

  return categoriesSet;
});
