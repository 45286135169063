import { useGetApplicationsStatsQuery } from '@store/api/applications.api';
import { selectActiveTab } from '@store/slices/customer/selectors';
import { customerActions } from '@store/slices/customer/slice';
import { ApplicationsTotalTarget } from '@widgets/cabinet/enums';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CabinetTabs } from '../../cabinetTabs/CabinetTabs';
import { cabinetTabsData } from '../constants';

export function CabinetCustomerTabs() {
  const dispatch = useDispatch();
  const activeTab = useSelector(selectActiveTab);

  const { data: applicationsStats, isLoading } = useGetApplicationsStatsQuery({
    target: ApplicationsTotalTarget.Customer,
  });

  const tabList = useMemo(
    () =>
      cabinetTabsData.map((item) => ({
        ...item,
        isActive: item.eventKey === activeTab,
        indication: item.indicationDisable ? null : applicationsStats?.byState[item.eventKey] || 0,
        isLoading,
        onClick: () => dispatch(customerActions.changeActiveTab(item.eventKey)),
      })),
    [applicationsStats, activeTab, isLoading],
  );

  return <CabinetTabs tabList={tabList} className="mt-8" />;
}
