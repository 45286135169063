import { useGetIconOptimized } from '@shared/hooks/useGetIconOptimized';
import { ModalBasic, ModalBasicProps } from '@shared/ui/modal/ModalBasic';
import { Application } from '@widgets/cabinet/applicationCard/types';
import { format } from 'date-fns';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type ModalGratitudeHandToHandProps = Pick<ModalBasicProps, 'isVisible' | 'onClose'> & { application: Application };

const useComponentState = ({ application, isVisible, onClose }: ModalGratitudeHandToHandProps) => {
  const { t } = useTranslation();
  const icon = { optimized: useGetIconOptimized('happy-customer-sm') };

  const title: string | null = useMemo(() => {
    if (!application || !application.updatedAt) {
      return null;
    }

    const key = application.purchase ? 'congrats-got-help' : 'congrats-got-hand-help';

    return t(key, { date: format(new Date(application.updatedAt), 'dd.MM.yyyy') });
  }, [application]);

  return {
    icon,
    title,
    isVisible,
    onClose,
  };
};

export const ModalGotHelpShareReview: React.FC<ModalGratitudeHandToHandProps> = (props) => {
  const { t } = useTranslation();
  const { icon, title, isVisible, onClose } = useComponentState(props);
  if (!title) {
    return null;
  }

  return (
    <ModalBasic
      hasHeaderCloseBtn={true}
      isVisible={isVisible}
      title={title}
      imageHeading={icon}
      hasModalFooter={false}
      onClose={onClose}
    >
      <p
        dangerouslySetInnerHTML={{ __html: t('share-review-in-socials') }}
        className="prose-a:text-shamrock prose-a:no-underline prose-a:cursor-pointer"
      ></p>
    </ModalBasic>
  );
};
