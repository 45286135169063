import { useCancelCustomerApplicationItems } from '@shared/hooks/useCancelCustomerApplicationItems';
import { Loader } from '@shared/ui/loader/Loader';
import { LoaderWrapper } from '@shared/ui/loader/LoaderWrapper';
import React, { useState } from 'react';
import { ApplicationCardAddress } from './ApplicationCardAddress';
import { ApplicationCardAmount } from './ApplicationCardAmount';
import { ApplicationCardApplicantCategories } from './ApplicationCardApplicantCategories';
import { ApplicationCardCollapseController } from './ApplicationCardCollapseController';
import { ApplicationCardNeedsHolder } from './ApplicationCardNeedsHolder';
import { CustomerCurrentApplicationViewCardItems } from './CustomerCurrentApplicationCardItems';
import { DenyApplicationButton } from './DenyApplicationButton';
import { ApplicationCardProps } from './types';
import { ApproveApplicationButton } from './ApproveApplicationButton';
import { useApproveApplicationItemsMutation } from '@store/api/applications.api';
import { CustomerHandToHandApplicationCardItems } from './CustomerHandToHandApplicationCardItems';
import { useRemoveHandToHandItemsHandler } from './useRemoveHandToHandHandler';
import { useRemoveInProgressItemsHandler } from './useRemoveInProgressHandler';

const useApproveCustomerApplicationItems = () => {
  const [approveHandler] = useApproveApplicationItemsMutation();
  const [isLoading, setIsLoading] = useState(false);

  const onApprove = async (dto: number[]) => {
    console.log('onApprove', dto);
    try {
      setIsLoading(true);
      await approveHandler({ items: dto.map((id) => ({ id })) }).unwrap();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return [onApprove, { isLoading }] as const;
};

type CustomerHandToHandApplicationCardProps = ApplicationCardProps & {
  loading?: boolean;
};

const VolunteerSection = () => {
  return null;
};

function useIsCollapsed() {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const toggleCollapsedHandler = () => setIsCollapsed((isCollapsed) => !isCollapsed);

  return { isCollapsed, toggleCollapsedHandler } as const;
}

function usePerformCardState(props: CustomerHandToHandApplicationCardProps, actions: any) {
  const { isCollapsed, toggleCollapsedHandler } = useIsCollapsed();

  return {
    applicationId: props.id,
    purchase: props.purchase,
    view: props.view,
    volunteer: props.volunteer,
    applicationCardAddressProps: {
      address: props.customer.address,
    },
    applicationCardAmountProps: {
      amount: props.items.length,
      label: 'total-needs',
    },
    applicationCardItemsProps: {
      items: props.items,
      view: props.view,
    },
    applicationCardWhoNeedsProps: {
      customer: props.customer,
      view: props.view,
    },
    collapsedProps: {
      isCollapsed,
      toggleCollapsedHandler,
    },
    denyButtonProps: {
      needConfirm: true,
      onClick: () => actions.onRemove(props.items.map((item) => item.id)),
    },
  } as const;
}

export const CustomerInProgressApplicationCard = (props: CustomerHandToHandApplicationCardProps) => {
  const onRemove = useRemoveInProgressItemsHandler();

  const {
    view,
    applicationCardAddressProps,
    applicationCardAmountProps,
    applicationCardItemsProps,
    applicationCardWhoNeedsProps,
    denyButtonProps,
    collapsedProps,
  } = usePerformCardState(props, { onRemove });

  return (
    <div className="relative p-[24px] lg:p-[32px] bg-white border-hawkes-blue border-2 rounded-lg">
      <VolunteerSection />
      <div className="flex items-start justify-between w-100">
        <ApplicationCardAddress {...applicationCardAddressProps} className="mb-4" />
        <ApplicationCardCollapseController
          className="hidden lg:flex"
          onClick={collapsedProps.toggleCollapsedHandler}
          isCollapsed={collapsedProps.isCollapsed}
          view={view}
        />
      </div>
      <div className="flex flex-col md:flex-row md:justify-between">
        <ApplicationCardAmount {...applicationCardAmountProps} />
        <div>
          <DenyApplicationButton className="mt-2 lg:ml-auto mx-auto lg:mr-0" {...denyButtonProps} />
        </div>
      </div>
      <div className="flex justify-center">
        <ApplicationCardCollapseController
          className="flex mx-auto lg:hidden"
          onClick={collapsedProps.toggleCollapsedHandler}
          isCollapsed={collapsedProps.isCollapsed}
          view={view}
        />
      </div>
      <ApplicationCardNeedsHolder isCollapsed={collapsedProps.isCollapsed}>
        <CustomerHandToHandApplicationCardItems {...applicationCardItemsProps} />
        <ApplicationCardApplicantCategories {...applicationCardWhoNeedsProps} />
      </ApplicationCardNeedsHolder>
    </div>
  );
};
