import { filterObjectWithPositiveFields } from '@shared/utils';
import { selectApplicationItemsToTake } from '@store/slices/applications/selectors';
import { ApplicationItem, SimpleApplication } from '@widgets/cabinet/applicationCard/types';
import { ApplicationsTotalTarget } from '@widgets/cabinet/enums';
import { RootState } from '..';
import { emptySplitApi } from '../emptySplitApi';
import {
  CreateApplicationRequest,
  GetApplicationsStatsResponse,
  GetUnusedCertificatesResponse,
  PaginationList,
} from './types';

const extendedApi = emptySplitApi
  .enhanceEndpoints({
    addTagTypes: ['Applications', 'Certificates'],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      createApplication: build.mutation<void, CreateApplicationRequest>({
        query: (application) => ({
          url: '/applications/create',
          method: 'POST',
          body: application,
        }),
        invalidatesTags: ['Applications'],
      }),
      getApplicationsStats: build.query<GetApplicationsStatsResponse, any>({
        query: (params) => ({ url: '/applications/total', params }),
        providesTags: ['Applications'],
      }),
      getApplications: build.query({
        query: (params) => {
          const filteredParams = filterObjectWithPositiveFields(params);
          return {
            url: '/applications',
            params: filteredParams,
          };
        },
        providesTags: ['Applications'],
      }),
      getTotalActiveApplications: build.query({
        query: (params) => {
          const filteredParams = filterObjectWithPositiveFields(params);

          return {
            url: '/applications/total/active',
            params: filteredParams,
          };
        },
        providesTags: ['Applications'],
      }),
      getApplicationsFilters: build.query({
        query: (params) => {
          const filteredParams = filterObjectWithPositiveFields(params);

          return {
            url: '/applications/filters',
            params: filteredParams,
          };
        },
        providesTags: ['Applications'],
      }),
      takeApplicationItems: build.mutation<any, void>({
        queryFn: (_args, api, _extraOptions, baseQuery) => {
          const applicationItemsToTake = selectApplicationItemsToTake(api.getState() as RootState);

          return baseQuery({
            url: '/applications/items/take',
            method: 'POST',
            body: { items: applicationItemsToTake },
          });
        },
        invalidatesTags: ['Applications'],
      }),
      executeApplicationItems: build.mutation<any, { items: { id: number }[] }>({
        query: (body) => ({
          url: '/applications/items/perform',
          method: 'POST',
          body,
        }),
        invalidatesTags: ['Applications'],
      }),
      prolongApplicationItems: build.mutation<any, { items: { id: number }[], inProgressTo: string }>({
        query: (body) => ({
          url: '/applications/items/prolong',
          method: 'POST',
          body,
        }),
        invalidatesTags: ['Applications'],
      }),
      cancelApplicationItems: build.mutation<
        any,
        { target: ApplicationsTotalTarget; itemIds: number[]; state?: 'in_progress' | 'processed' }
      >({
        query: (args) => ({ url: '/applications/items/cancel', method: 'POST', body: args }),
        invalidatesTags: ['Applications'],
      }),
      approveApplicationItems: build.mutation<any, { items: { id: number }[] }>({
        query: (body) => ({ url: '/applications/items/compleete', method: 'POST', body }),
        invalidatesTags: ['Applications'],
      }),
      cancelApplicationCertificate: build.mutation<any, string>({
        query: (certificateId) => ({ url: `/certificates/${certificateId}/cancel`, method: 'POST' }),
        invalidatesTags: ['Certificates', 'Applications'],
      }),
      getUnusedCertificates: build.query<GetUnusedCertificatesResponse, void>({
        query: () => ({
          url: '/certificates/unused',
        }),
        providesTags: ['Certificates'],
      }),
      getCustomerApplications: build.query<
        PaginationList<SimpleApplication>,
        { userId: string; state?: string; page?: number; perPage?: number }
      >({
        query: ({ userId, state, page = 1, perPage = 10 }) => ({
          url: `/applications/users/${userId}/customer`,
          params: { page, state, perPage },
        }),
      }),
    }),
    overrideExisting: false,
  });

export const {
  useGetApplicationsStatsQuery,
  useGetApplicationsQuery,
  useGetTotalActiveApplicationsQuery,
  useGetApplicationsFiltersQuery,
  useLazyGetApplicationsFiltersQuery,
  useTakeApplicationItemsMutation,
  useCreateApplicationMutation,
  useCancelApplicationItemsMutation,
  useCancelApplicationCertificateMutation,
  useGetUnusedCertificatesQuery,
  useLazyGetCustomerApplicationsQuery,
  useApproveApplicationItemsMutation,
  useExecuteApplicationItemsMutation,
  useProlongApplicationItemsMutation,
} = extendedApi;
