import React, { useMemo, useState } from 'react';
import { Button } from '@shared/ui/buttons/Button';
import { CheckboxNotFormField } from '@shared/ui/CheckboxNotFormField';
import { ErrorList } from '@shared/ui/ErrorList';
import { GenericLink } from '@shared/ui/GenericLink';
import { isApiError, translateApiError } from '@shared/utils/errors';
import { useCreateApplicationMutation } from '@store/api/applications.api';
import { ApplicationCardRemovableItem } from '@widgets/cabinet/applicationCard/ApplicationCardRemovableItem';
import { useTranslation } from 'react-i18next';
import { useGetPrettyLocalityName } from '@shared/hooks/usePrettyLocalityName';
import { useProfile } from '@shared/hooks/useProfile';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { selectApplicationItems } from '@store/slices/customer/selectors';
import { customerActions } from '@store/slices/customer/slice';
import { ModalCustomerChooseStore } from '@widgets/cabinet/volunteer/modals/ModalCustomerChooseStore';
import { useGetApplicationDto } from '../../hooks/useGetApplicationDto';
import { useItemsStoreCategories } from '../../hooks/useItemsStoreCategories';

export const CreateApplicationForm: React.FC = () => {
  const items = useAppSelector(selectApplicationItems);
  const { hasStoreCategories } = useItemsStoreCategories();

  const dispatch = useAppDispatch();
  const [createApplication, { isLoading }] = useCreateApplicationMutation();
  const [apiErrors, setApiErrors] = useState<string[]>([]);

  const [localityApproved, setLocalityApproved] = useState(false);
  const [needHelpApproved, setNeedHelpApproved] = useState(false);

  const profile = useProfile();
  const getPrettyLocalityName = useGetPrettyLocalityName();

  const { t } = useTranslation();
  const applicationDto = useGetApplicationDto();

  const isSubmitButtonDisabled = useMemo(() => {
    return items.length <= 0 || isLoading || !localityApproved || !needHelpApproved;
  }, [items, isLoading, localityApproved, needHelpApproved]);

  const handleRemove = (id: number) => {
    dispatch(customerActions.removeApplicationItem(id));
  };

  async function onApplicationCreate() {
    try {
      setApiErrors([]);
      await createApplication(applicationDto).unwrap();
      dispatch(customerActions.changeCategoryId(''));
      dispatch(customerActions.clearApplicationItems());
      dispatch(customerActions.clearChooseStoreState());
      setLocalityApproved(false);
      dispatch(customerActions.setModal('create-application'));
    } catch (err) {
      if (isApiError(err)) {
        setApiErrors(translateApiError(err, t));
      }
    }
  }

  function handlePrimaryButtonClick() {
    if (hasStoreCategories) {
      dispatch(customerActions.setModal('customer-choose-store'));
    } else {
      onApplicationCreate();
    }
  }

  const formattedLocality = useMemo(() => {
    if (!profile) return null;

    const { address } = profile;
    if (!address?.locality || !address?.country || !address?.region) return null;

    return getPrettyLocalityName(address.locality.name, address.locality.category) + `, ${t(address.region.name)} обл.`;
  }, [getPrettyLocalityName, profile]);

  return (
    <div className="flex flex-col items-start">
      <h2 className="text-xl md:text-2xl mb-7">{t('your-application')}</h2>

      <div className="mb-4">
        {items.map((item) => (
          <div key={item.id} className="mt-5 first:mt-0">
            <ApplicationCardRemovableItem {...item} onRemove={handleRemove} />
          </div>
        ))}

        {items.length <= 0 && <span className="text-sm italic text-gray">{t('empty-add-needs')}</span>}
      </div>

      <div className="mt-auto mb-3">
        {formattedLocality && (
          <CheckboxNotFormField
            name="locality-approved"
            isChecked={localityApproved}
            className="mt-3"
            label={
              <>
                {t('your-place')} - <b>{formattedLocality}</b> {t('change-your-place')}{' '}
                <GenericLink type="page" className="text-shamrock hover:underline" to="/profile">
                  {t('profile-2')}
                </GenericLink>
              </>
            }
            onChange={(e) => setLocalityApproved(e.target.checked)}
          />
        )}
        <CheckboxNotFormField
          name="need-help-approved"
          isChecked={needHelpApproved}
          className="mt-3"
          label={t('need-help-approve')}
          onChange={(e) => setNeedHelpApproved(e.target.checked)}
        />
      </div>

      <ErrorList errors={apiErrors} className="mt-5" />

      <div className="flex w-full mt-8 justify-center md:justify-start">
        <Button
          as="button"
          variant="primary"
          type="submit"
          disabled={isSubmitButtonDisabled}
          onClick={handlePrimaryButtonClick}
        >
          {t('create')}
        </Button>
      </div>

      <ModalCustomerChooseStore onApprove={onApplicationCreate} isLoading={isLoading} />
    </div>
  );
};
