import React from 'react';
import { useSelector } from 'react-redux';

import { selectActiveTab } from '@store/slices/customer/selectors';

import { CabinetCustomerCreateApplication } from './content/CabinetCustomerCreateApplication';
import { CabinetCustomerApplications } from './content/CabinetCustomerApplications';
import { CABINET_TAB_CREATE } from './constants';

export const CabinetCustomerContent = () => {
  const activeTab = useSelector(selectActiveTab);

  return activeTab === CABINET_TAB_CREATE ? (
    <CabinetCustomerCreateApplication />
  ) : (
    <CabinetCustomerApplications type={activeTab} />
  );
};
