import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { HowToHelpType, PurchaseStoreType } from '@shared/enums';

import { PrimaryDropdown } from '@shared/dropdowns/PrimaryDropdown';
import { Element } from '@shared/interfaces/element.interface';
import { useIntegrationConfigs } from '@hooks/useIntegrationConfigs';

interface Props extends Element {
  value: string;

  onChange: (value: string) => void;

  errorText?: string;
}

function useComponentState() {
  const { t } = useTranslation();

  const integrationConfigs = useIntegrationConfigs();
  const fuelShops = integrationConfigs.find((config) => config.type === HowToHelpType.OnlineFuel)?.shops || [];

  const fuelOptions = useMemo(
    () =>
      fuelShops.map((item) => ({
        value: item.name,
        label: t(item.label),
      })),
    // .concat({ value: PurchaseStoreType.Default, label: t('gas-stations-missing') }),
    [fuelShops, t],
  );

  return {
    t,
    fuelOptions,
  };
}

export const GasStationDropdown: React.FC<Props> = ({ value, className, errorText, onChange }) => {
  const { t, fuelOptions } = useComponentState();

  return (
    <>
      <PrimaryDropdown
        value={value}
        onChange={onChange}
        options={fuelOptions}
        className={className}
        label={t('choose-gas-station')}
        errorText={errorText && t(errorText)}
      />
    </>
  );
};
