import React from 'react';

import { StaticBanner } from '@widgets/banners/static-banner';
import { CabinetCustomerCanCreateApplicationBanner } from './CabinetCustomerCanCreateApplicationBanner';

export const CabinetCustomerBanners = () => {
  return (
    <div>
      <CabinetCustomerCanCreateApplicationBanner />

      {/* <div className="mt-8">
        <StaticBanner
          languages={['uk']}
          img={{ horizontal: 'santa-claus-customer-wide', vertical: 'santa-claus-customer' }}
          link="/santa-claus/customer"
        />
      </div> */}
    </div>
  );
};
