import { IconTrashBin } from '@shared/icons/IconTrashBin';
import { Button } from '@shared/ui/buttons/Button';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetModalConfirmProps } from '../volunteer/modals/hooks/useGetModalConfirmProps';
import { ModalConfirmAction } from '../volunteer/modals/ModalConfirmAction';
import { IconMark } from '@shared/icons/IconMark';
import { InputCloseIcon } from '@shared/icons/InputCloseIcon';
import { ModalBasic } from '@shared/ui/modal/ModalBasic';
import { Customer } from './types';
import { Link } from '@shared/ui/Link';

type ApproveApplicationButtonProps = {
  onClick: () => void;
  needConfirm?: boolean;
  className?: string;
  volunteer: Customer | null;
};

function useComponentState(props: ApproveApplicationButtonProps) {
  const { onClick, needConfirm } = props;
  const { t } = useTranslation();
  const modalConfirmProps = useGetModalConfirmProps({
    title: 'confirm-hand-to-hand',
    onConfirm: onClick,
  });

  const handleButtonClick = () => {
    if (needConfirm) {
      modalConfirmProps.onClose();
      return;
    }
    onClick();
  };

  return {
    ...props,
    t,
    handleButtonClick,
    modalConfirmProps,
  };
}

export const ApproveApplicationButton: React.FC<ApproveApplicationButtonProps> = (props) => {
  const { t, volunteer, handleButtonClick, modalConfirmProps, className } = useComponentState(props);
  console.log('volunteer', volunteer);
  return (
    <div>
      <Button
        as="button"
        variant="rich-blue"
        size="tiny"
        onClick={handleButtonClick}
        iconClassName="mr-1"
        className={clsx('flex justify-center', className)}
      >
        <span className="flex items-center">
          <IconMark />

          {t('confirm')}
        </span>
      </Button>
      <ModalBasic {...modalConfirmProps} secondaryBtnLabel="cancel-2" approveBtnLabel="confirm">
        <div>
          <span>{t('confirm-hand-to-hand.description-1')}</span>
          <span className="mx-1">
            {volunteer?.name ? (
              <Link to={`/customer/profile/${volunteer.id}`} style={{ color: '#4db56f' }}>
                {volunteer?.name}
              </Link>
            ) : (
              'Гость'
            )}
          </span>
          <span>{t('confirm-hand-to-hand.description-2')}</span>
        </div>
      </ModalBasic>
    </div>
  );
};
