import { IconTrashBin } from '@shared/icons/IconTrashBin';
import { Pill } from '@shared/ui/Pill';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetModalConfirmProps } from '../volunteer/modals/hooks/useGetModalConfirmProps';
import { ModalConfirmAction } from '../volunteer/modals/ModalConfirmAction';
import { ApplicationCardItemProps } from './types';

type ApplicationCardRemovableItemProps = ApplicationCardItemProps & {
  onRemove?: (itemId: number) => void;
  needConfirm?: boolean;
};

function useApplicationCardRemovableItemState({
  name,
  category,
  amount,
  unit,
  id,
  needConfirm = false,
  onRemove,
}: ApplicationCardRemovableItemProps) {
  const { t } = useTranslation();
  const modalConfirmProps = useGetModalConfirmProps({
    title: 'remove-need',
    onConfirm: () => onRemove!(id),
  });

  const itemClickHandler = useCallback(() => {
    if (needConfirm) {
      modalConfirmProps.onClose();
      return;
    } else if (onRemove) {
      onRemove(id);
    }
  }, [id]);

  return {
    itemClickHandler,
    categoryName: t(category.name),
    itemName: t(name),
    amountWithUnit: `${amount}${t(unit || '')}`,
    needConfirm,
    modalConfirmProps,
  } as const;
}

export const ApplicationCardRemovableItem = (props: ApplicationCardRemovableItemProps) => {
  const { itemClickHandler, needConfirm, modalConfirmProps, categoryName, itemName, amountWithUnit } =
    useApplicationCardRemovableItemState(props);

  return (
    <>
      <div className="flex mt-2 first:mt-0">
        <IconTrashBin className="w-[24px] h-[24px] mt-1 mr-3 cursor-pointer shrink-0" onClick={itemClickHandler} />
        <div className="flex flex-wrap gap-x-2 gap-y-2">
          <Pill className="bg-granny-apple shrink-0">{categoryName}</Pill>
          <Pill className="bg-granny-apple">{itemName}</Pill>
          <Pill className="bg-yellow-apple shrink-0">{amountWithUnit}</Pill>
        </div>
      </div>
      {needConfirm && <ModalConfirmAction {...modalConfirmProps} />}
    </>
  );
};
