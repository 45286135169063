import { ModalNames } from '@shared/enums';
import { Button } from '@shared/ui/buttons/Button';
import { CategoryDropdown } from '@shared/ui/dropdowns/CategoryDropdown';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { selectCategoryId } from '@store/slices/customer/selectors';
import { customerActions } from '@store/slices/customer/slice';
import { ModalChooseCustomerNeeds } from '@widgets/cabinet/volunteer/modals/ModalChooseCustomerNeeds';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCanCreateApplication } from '../../hooks/useCanCreateApplication';

export const CreateApplicationItemForm: React.FC = () => {
  const categoryId = useAppSelector(selectCategoryId);
  const [canCreate] = useCanCreateApplication();
  const dispatch = useAppDispatch();

  const handleClickChooseNeeds = () => {
    dispatch(customerActions.setModal(ModalNames.AddCustomerNeeds));
  };
  const handleCategoryChange = (id: number | string) => {
    dispatch(customerActions.changeCategoryId(id));
  };
  const { t } = useTranslation();

  return (
    <div className="mb-14">
      <h2 className="text-xl md:text-2xl mb-5">{t('add-to-application')}</h2>
      <div className="max-w-[525px]">
        <CategoryDropdown value={categoryId as string} onChange={handleCategoryChange} />
      </div>
      <div className="flex w-full mt-10 justify-center md:justify-start">
        <Button as="button" variant="primary" onClick={handleClickChooseNeeds} disabled={!canCreate || !categoryId}>
          {t('Обрати потреби')}
        </Button>
      </div>
      <ModalChooseCustomerNeeds />
    </div>
  );
};
