import { useShops } from '@hooks/useShops';
import { HowToHelpType } from '@shared/enums';
import { useAppSelector } from '@store/hooks';
import { selectGasStation } from '@store/slices/customer/selectors';

export function useGasStationRulesCheckboxVisibility() {
  const gasStation = useAppSelector(selectGasStation);

  const shops = useShops(HowToHelpType.OnlineFuel);
  const gasStationValidValues = shops.map((shop) => shop.name);

  return gasStationValidValues.includes(gasStation);
}
