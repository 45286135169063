import { HowToHelpType, PurchaseCategoryType, PurchaseStoreType } from '@shared/enums';
import { ApplicationShops } from '@store/api/types';
import { useAppSelector } from '@store/hooks';
import {
  selectApplicationItems,
  selectApplicationItemsCategories,
  selectChildProtectionShop,
  selectChildrenToysShop,
  selectEasterShop,
  selectGasStation,
  selectPharmacy,
  selectSupermarket,
} from '@store/slices/customer/selectors';
import { useMemo } from 'react';

export function useGetApplicationDto() {
  const applicationItems = useAppSelector(selectApplicationItems);
  const selectedSupermarket = useAppSelector(selectSupermarket);
  const selectedPharmacy = useAppSelector(selectPharmacy);
  const selectedGasStation = useAppSelector(selectGasStation);
  const selectedCategories = useAppSelector(selectApplicationItemsCategories);
  const selectedEasterShop = useAppSelector(selectEasterShop);
  const selectedChildProtectionShop = useAppSelector(selectChildProtectionShop);
  const selectedChildrenToysShop = useAppSelector(selectChildrenToysShop);
  /**
   * TODO: reworking. Why we hardcode `PurchaseCategoryType.Fuel` and `PurchaseCategoryType.Clothes` here?
   */
  const hasFuelCategory = selectedCategories.has(PurchaseCategoryType.Fuel);
  const hasClothesCategory = selectedCategories.has(PurchaseCategoryType.Clothes);
  const itemsOutput = useMemo(() => {
    return applicationItems.map((item) => ({
      categoryItemId: item.id,
      amount: item.amount,
    }));
  }, [applicationItems]);

  const shopsOutput: ApplicationShops[] = [];

  const supermarket = selectedSupermarket
    ? { shopType: HowToHelpType.OnlineFood, shopName: selectedSupermarket }
    : null;
  const pharmacy = selectedPharmacy ? { shopType: HowToHelpType.OnlineMedicaments, shopName: selectedPharmacy } : null;
  const fuel = hasFuelCategory ? { shopType: HowToHelpType.OnlineFuel, shopName: selectedGasStation } : null;
  const easter = selectedEasterShop ? { shopType: HowToHelpType.Easter, shopName: selectedEasterShop } : null;
  const сhildProtection = selectedChildProtectionShop
    ? { shopType: HowToHelpType.ChildrenProtected, shopName: selectedChildProtectionShop }
    : null;
  const childrenToys = selectedChildrenToysShop
    ? { shopType: HowToHelpType.ChildrenToy, shopName: selectedChildrenToysShop }
    : null;
  const clothes = hasClothesCategory
    ? { shopType: PurchaseStoreType.Default, shopName: PurchaseStoreType.Default }
    : null;

  const purchaseVariants = [supermarket, pharmacy, fuel, clothes, easter, сhildProtection, childrenToys];
  purchaseVariants.forEach((item) => {
    if (item) {
      shopsOutput.push(item);
    }
    return;
  });

  return {
    items: itemsOutput,
    shops: shopsOutput,
  };
}
