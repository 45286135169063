import { useMemo } from 'react';

import { ApplicationsTotalTarget } from '@widgets/cabinet/enums';
import { UsePagination } from '@shared/hooks/usePagination';

export function useCustomerApplicationsParams(pagination: Partial<UsePagination>, type: string) {
  return useMemo(
    () =>
      type === 'hand-to-hand'
        ? {
            target: ApplicationsTotalTarget.Customer,
            state: type,
            page: pagination.page ?? 1,
            perPage: pagination.perPage ?? 10,
          }
        : {
            target: ApplicationsTotalTarget.Customer,
            state: type || 'new',
            page: pagination.page ?? 1,
            perPage: pagination.perPage ?? 10,
          },
    [pagination, type],
  );
}
