import { ApplicationState } from '@shared/enums';
import { sortBy } from '@shared/utils/arrays';
import { useGetApplicationsQuery } from '@store/api/applications.api';
import { useAppDispatch } from '@store/hooks';
import { selectActiveTab, selectModal } from '@store/slices/customer/selectors';
import { customerActions } from '@store/slices/customer/slice';
import { Application } from '@widgets/cabinet/applicationCard/types';
import { ModalGotHelpShareReview } from '@widgets/cabinet/volunteer/modals/ModalGotHelpShareReview';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useCustomerApplicationsParams } from '../hooks/useCustomerApplicationsParams';

function useComponentState() {
  const dispatch = useAppDispatch();
  const activeTab = useSelector(selectActiveTab);
  const modal = useSelector(selectModal);
  const params = useCustomerApplicationsParams({}, ApplicationState.Completed);
  const { data = { items: [] } } = useGetApplicationsQuery(params);
  const { items: applications } = data;

  const lastCompletedApplication = useMemo(
    () => sortBy(applications, (item: Application) => new Date(item.updatedAt).getTime()).at(-1),
    [applications],
  );

  const isActiveTabCompleted = activeTab === ApplicationState.Completed;
  useEffect(() => {
    if (isActiveTabCompleted && lastCompletedApplication) {
      dispatch(customerActions.setModal('got-help-share-review'));
    }
  }, [isActiveTabCompleted, applications]);

  const isModalVisible = useMemo(() => modal === 'got-help-share-review', [modal]);
  const handleCloseModal = useCallback(() => dispatch(customerActions.setModal(null)), []);

  const modalProps = useMemo(
    () => ({
      application: lastCompletedApplication,
      isVisible: isModalVisible,
      onClose: handleCloseModal,
    }),
    [lastCompletedApplication, isModalVisible, handleCloseModal],
  );
  return {
    modalProps,
  };
}

export const ModalGotHelpShareReviewCustomerCabinetWidget = () => {
  const { modalProps } = useComponentState();
  if (!modalProps.application) {
    return null;
  }

  return <ModalGotHelpShareReview {...modalProps} />;
};
