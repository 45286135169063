import { IconCheckboxForm } from '@shared/icons/IconCheckboxForm';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxListItem } from './DropdownCheckboxListWithSearch';

interface Props {
  isOpen: boolean;
  list: CheckboxListItem[];
  onChangeAmount: (needId: number, amount: number) => void;
}

function useComponentState(props: Props) {
  const { onChangeAmount } = props;
  const { t } = useTranslation();
  const handleDecreaseAmount = (id: number, amount: number) => {
    if (amount === 0) return;
    onChangeAmount(id, --amount);
  };
  const handleIncreaseAmount = (id: number, amount: number) => {
    onChangeAmount(id, ++amount);
  };
  const handleChangeChecked = (id: number, isChecked: boolean) => {
    isChecked ? onChangeAmount(id, 0) : onChangeAmount(id, 1);
  };

  return {
    t,
    handleDecreaseAmount,
    handleIncreaseAmount,
    handleChangeChecked,
    ...props,
  };
}

export const DropdownListWithAmountsMenu: React.FC<Props> = (props) => {
  const { t, isOpen, list, handleDecreaseAmount, handleIncreaseAmount, handleChangeChecked } = useComponentState(props);

  return (
    <div
      className={clsx(
        'absolute left-0 top-full w-full max-h-[412px] overflow-y-auto mt-1.5 py-4 bg-white shadow-snackbar',
        !isOpen && 'hidden',
      )}
    >
      {list.length > 0 ? (
        <ul>
          {list.map(({ id, amount, isChecked, label }) => (
            <li key={id} className="flex px-4 py-[7px]">
              <IconCheckboxForm
                checked={isChecked}
                className="cursor-pointer"
                onClick={() => handleChangeChecked(id, isChecked)}
              />
              <span className="block flex-1 pl-4 font-light" onClick={() => handleChangeChecked(id, isChecked)}>
                {label}
              </span>
              <div className="flex items-center">
                <span className="px-2 text-[26px] cursor-pointer" onClick={() => handleDecreaseAmount(id, amount)}>
                  &#8722;
                </span>
                <span className={clsx('block min-w-[40px] text-xs text-center', amount < 1 && 'text-black-50')}>
                  {amount}
                </span>
                <span className="px-2 text-[26px] cursor-pointer" onClick={() => handleIncreaseAmount(id, amount)}>
                  &#43;
                </span>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p className="px-4">{t('no-needs-available')}</p>
      )}
    </div>
  );
};
