import { useApplicationCertificateActions } from '@shared/hooks/useApplicationCertificateActions';
import { Loader } from '@shared/ui/loader/Loader';
import { LoaderWrapper } from '@shared/ui/loader/LoaderWrapper';
import React, { useState } from 'react';
import { ApplicationCardAddress } from './ApplicationCardAddress';
import { ApplicationCardAmount } from './ApplicationCardAmount';
import { ApplicationCardApplicantCategories } from './ApplicationCardApplicantCategories';
import { ApplicationCardCollapseController } from './ApplicationCardCollapseController';
import { ApplicationCardNeedsHolder } from './ApplicationCardNeedsHolder';
import { CancelCertificateButtonWidget } from './CancelCertificateButtonWidget';
import { CompleteApplicationCardItems } from './CompleteApplicationCardItems';
import { GenerateCertificateButton } from './GenerateCertificateButton';
import { ApplicationCardProps } from './types';

function useIsCollapsed() {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const toggleCollapsedHandler = () => setIsCollapsed((isCollapsed) => !isCollapsed);

  return { isCollapsed, toggleCollapsedHandler } as const;
}

function useActiveCardState(props: ApplicationCardProps) {
  const { isCollapsed, toggleCollapsedHandler } = useIsCollapsed();

  return {
    applicationId: props.id,
    purchase: props.purchase,
    view: props.view,
    shops: props.shops,
    applicationCardAddressProps: {
      address: props.customer.address,
    },
    applicationCardAmountProps: {
      amount: props.items.length,
    },
    applicationCardItemsProps: {
      items: props.items,
      view: props.view,
    },
    applicationCardWhoNeedsProps: {
      customer: props.customer,
      view: props.view,
    },
    collapsedProps: {
      isCollapsed,
      toggleCollapsedHandler,
    },
  } as const;
}

/**
 * NOTE: temporary because one card is implementing this feature
 */
const temporaryIsOneCardShop = (shops: ApplicationCardProps['shops']) => {
  if (shops && Array.isArray(shops)) {
    return shops.find((shop) => shop.name === 'one-card');
  }

  return false;
};

export const CustomerProcessedApplicationCard = (props: ApplicationCardProps) => {
  const {
    applicationCardAddressProps,
    applicationCardAmountProps,
    applicationCardItemsProps,
    applicationCardWhoNeedsProps,
    collapsedProps,
    purchase,
    view,
    shops,
  } = useActiveCardState(props);
  const [actions, { isLoading }] = useApplicationCertificateActions(props.purchase?.id || null);

  return (
    <div className="relative p-[24px] lg:p-[32px] bg-white border-hawkes-blue border-2 rounded-lg">
      {isLoading && (
        <LoaderWrapper className="rounded-lg">
          <Loader className="h-32" />
        </LoaderWrapper>
      )}
      <div className="flex items-start justify-between w-100">
        <ApplicationCardAddress {...applicationCardAddressProps} className="mb-4" />
        <ApplicationCardCollapseController
          className="hidden lg:flex"
          onClick={collapsedProps.toggleCollapsedHandler}
          isCollapsed={collapsedProps.isCollapsed}
          view={view}
        />
      </div>
      <div className="flex flex-col md:flex-row md:justify-between">
        <ApplicationCardAmount className="mr-auto" {...applicationCardAmountProps} />

        {purchase && purchase.id && (
          <>
            <GenerateCertificateButton
              className="mx-auto mt-5 mx:mx-0 md:mt-0"
              purchase={purchase}
              onClick={actions.generateCertificate}
            />

            {!temporaryIsOneCardShop(shops) && (
              <CancelCertificateButtonWidget
                className="mx-auto my-5 md:mx-0 md:my-0"
                purchase={purchase}
                onClick={actions.cancelCertificate}
                needConfirm
              />
            )}
          </>
        )}
      </div>

      <div className="flex justify-center">
        <ApplicationCardCollapseController
          className="flex mx-auto lg:hidden"
          onClick={collapsedProps.toggleCollapsedHandler}
          isCollapsed={collapsedProps.isCollapsed}
          view={view}
        />
      </div>

      <ApplicationCardNeedsHolder isCollapsed={collapsedProps.isCollapsed}>
        <CompleteApplicationCardItems {...applicationCardItemsProps} />
        <ApplicationCardApplicantCategories {...applicationCardWhoNeedsProps} />
      </ApplicationCardNeedsHolder>
    </div>
  );
};
