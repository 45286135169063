import { useShops } from '@hooks/useShops';
import { HowToHelpType } from '@shared/enums';
import { useAppSelector } from '@store/hooks';
import { selectSupermarket } from '@store/slices/customer/selectors';

export function useSupermarketRulesCheckboxVisibility() {
  const supermarket = useAppSelector(selectSupermarket);

  const shops = useShops(HowToHelpType.OnlineFood);
  const supermarketsValidValues = shops.map((shop) => shop.name);

  return supermarketsValidValues.includes(supermarket);
}
