import React from 'react';

import { selectModal } from '@store/slices/customer/selectors';
import { customerActions } from '@store/slices/customer/slice';
import { useDispatch, useSelector } from 'react-redux';
import { ModalGratitudeCreateApplication } from '../volunteer/modals/ModalGratitudeCreateApplication';
import { CabinetCustomerContent } from './CabinetCustomerContent';
import { CabinetCustomerTabs } from './tabs';
import { ModalGotHelpShareReviewCustomerCabinetWidget } from './modals/ModalGotHelpShareReviewCustomerCabinetWidget';

export const CabinetCustomer = () => {
  const modal = useSelector(selectModal);
  const dispatch = useDispatch();

  return (
    <>
      <CabinetCustomerTabs />
      <div className="flex-col mt-6 grow gap-x-10 flex">
        <CabinetCustomerContent />
      </div>
      <ModalGratitudeCreateApplication
        isVisible={modal === 'create-application'}
        onApprove={() => {
          dispatch(customerActions.setModal(null));

          dispatch(customerActions.changeActiveTab('new'));
          window.scrollTo(0, 0);
        }}
        onClose={() => dispatch(customerActions.setModal(null))}
      />
      <ModalGotHelpShareReviewCustomerCabinetWidget />
    </>
  );
};
