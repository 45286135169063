import { useLanguage } from '@hooks/useLanguage';
import { useShops } from '@hooks/useShops';
import { HowToHelpType } from '@shared/enums';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { selectChildrenToysRules, selectChildrenToysShop } from '@store/slices/customer/selectors';
import { customerActions } from '@store/slices/customer/slice';
import React, { useCallback, useMemo } from 'react';
import { CustomerStoreRulesCheckbox } from './CustomerStoreRulesCheckbox';

import { useChildrenToysRulesCheckboxVisibility } from '../../hooks/useChildrenToysRulesCheckboxVisibility';
import { getRulesUrl } from './utils';

function useComponentState() {
  const language = useLanguage();
  const dispatch = useAppDispatch();
  const childProtectionShop = useAppSelector(selectChildrenToysShop);
  const childProtectionShopChecked = useAppSelector(selectChildrenToysRules);

  const shops = useShops(HowToHelpType.ChildrenToy);
  const selectedShop = shops.find((shop) => shop.name === childProtectionShop);

  const isRulesCheckboxVisible = useChildrenToysRulesCheckboxVisibility();
  const rulesLink = useMemo(() => {
    if (isRulesCheckboxVisible) {
      return getRulesUrl(selectedShop?.config.rules, language);
    }
  }, [isRulesCheckboxVisible, selectedShop, language]);

  const storeName = useMemo(() => {
    if (isRulesCheckboxVisible) {
      return selectedShop?.label;
    }
  }, [isRulesCheckboxVisible, selectedShop]);

  const handleCheckboxChange = useCallback((e) => {
    dispatch(customerActions.changeChildrenToysRules(e.target.checked));
  }, []);

  const customerStoreRulesCheckboxProps = {
    name: 'baby-products',
    checked: childProtectionShopChecked,
    rulesLink,
    storeName,
    onChange: handleCheckboxChange,
    className: 'first:mt-0 mt-3',
  };

  return {
    isRulesCheckboxVisible,
    customerStoreRulesCheckboxProps,
  };
}

export const CustomerChildrenToyRulesCheckbox = () => {
  const { isRulesCheckboxVisible, customerStoreRulesCheckboxProps } = useComponentState();
  if (!isRulesCheckboxVisible) return null;

  return <CustomerStoreRulesCheckbox {...customerStoreRulesCheckboxProps} />;
};
