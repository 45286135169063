import { ModalNames } from '@shared/enums';
import { useDictionaryCategoriesQuery } from '@store/api/dictionaries.api';
import { useAppSelector } from '@store/hooks';
import { selectApplicationItems, selectCategoryId, selectModal } from '@store/slices/customer/selectors';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCustomerModalVisbility } from './useCustomerModalVisbility';

export function useCategoryNeeds() {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>('');
  const categoryId = useAppSelector(selectCategoryId);
  const [categoryNeeds, setCategoryNeeds] = useState<any>([]);
  const confirmedNeeds = useAppSelector(selectApplicationItems);
  const { data: categories = [] } = useDictionaryCategoriesQuery();
  const isAddNeedsModalVisible = useCustomerModalVisbility(ModalNames.AddCustomerNeeds);

  const category = useMemo(() => {
    return categories.length > 0 && categoryId ? categories.find((category) => category.id === categoryId) : null;
  }, [categories, categoryId]);

  const categoryNeedsFiltered = useMemo(() => {
    return inputValue
      ? categoryNeeds.filter((item) => t(item.label).toLowerCase().includes(inputValue.toLowerCase()))
      : categoryNeeds;
  }, [inputValue, categoryNeeds, t]);

  useEffect(() => {
    if (!isAddNeedsModalVisible) {
      setCategoryNeeds([]);
    }
  }, [isAddNeedsModalVisible]);

  useEffect(() => {
    const categoryNeeds = category
      ? category.items
          .map((item) => {
            const confirmedNeed = confirmedNeeds.find((v) => v.id === item.id);
            return {
              id: item.id,
              label: t(item.name),
              amount: confirmedNeed ? confirmedNeed.amount : 0,
              isChecked: confirmedNeed ? true : false,
            };
          })
          .sort((a, b) => a.label.localeCompare(b.label))
      : [];

    setCategoryNeeds(categoryNeeds);
  }, [category, confirmedNeeds, isAddNeedsModalVisible]);

  const handleChangeInputValue = (e: any) => {
    setInputValue(e.target.value);
  };
  const handleClearInputValue = () => {
    setInputValue('');
  };

  const handleChangeNeedAmount = (id: number, amount: number) => {
    setCategoryNeeds((list) => {
      const newList = [...list];
      const need = newList.find((v) => v.id === id);
      need.amount = amount;
      need.isChecked = amount ? true : false;

      return [...newList];
    });
  };

  return {
    inputValue,
    categoryNeeds,
    categoryNeedsFiltered: categoryNeedsFiltered,
    handleChangeNeedAmount,
    handleChangeInputValue,
    handleClearInputValue,
    setCategoryNeeds,
  };
}
