import { ArrowDownIcon } from '@shared/icons/arrow-down';
import { InputCloseIcon } from '@shared/icons/InputCloseIcon';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  value: string;
  id: string;
  type?: string;
  label: string;
  className?: string;
  helperText?: string;
  clearable?: boolean;
  hasDropdownArrow?: boolean;
  isDropdownMenuVisible: boolean;
  onChange: (e: any) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onClear?: () => void;
  onDropdownToggle?: () => void;
}

function useComponentState(props: Props) {
  const { clearable, hasDropdownArrow } = props;
  const inputPaddingCn = clearable && hasDropdownArrow ? 'pr-8' : clearable || hasDropdownArrow ? 'pr-4' : '';

  return {
    inputPaddingCn,
    ...props,
  };
}

export const TextBasicField = (props: Props) => {
  const {
    value,
    id,
    type = 'text',
    label,
    className = '',
    helperText = '',
    clearable = false,
    hasDropdownArrow = false,
    isDropdownMenuVisible = true,
    inputPaddingCn,
    onChange = () => {},
    onFocus = () => {},
    onBlur = () => {},
    onClear = () => {},
    onDropdownToggle = () => {},
  } = useComponentState(props);

  const { t } = useTranslation();

  return (
    <div className={clsx('relative', className)}>
      <input
        value={value}
        type={type}
        id={id}
        placeholder={t(label)}
        className={clsx(
          'peer w-full h-[50px] pl-4 pt-5 pb-2 outline-none bg-transparent text-base text-black transition-all border-b-[1px] border-b-solid rounded-none placeholder:text-black-50 hover:bg-black-7 focus:bg-black-7 placeholder-shown:py-3',
          inputPaddingCn,
        )}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <label
        htmlFor={id}
        className="absolute left-0 top-[1px] px-4 pointer-events-none w-full transition-all text-xs text-black-50 peer-placeholder-shown:text-transparent peer-placeholder-shown:translate-y-3 peer-placeholder-shown:text-base"
      >
        {t(label)}
      </label>
      {clearable && value && (
        <button
          className={clsx(
            'absolute top-1/2 -translate-y-1/2 flex justify-center items-center px-1 py-3',
            hasDropdownArrow ? 'right-10' : 'right-0',
          )}
          type="button"
          onClick={onClear}
        >
          <InputCloseIcon className="w-5 h-5" />
        </button>
      )}
      {hasDropdownArrow && (
        <button
          className="absolute top-1/2 -translate-y-1/2 right-2 flex justify-center items-center px-2 py-3"
          type="button"
          onClick={onDropdownToggle}
        >
          <ArrowDownIcon className={clsx('transition-transform w-3 h-3', isDropdownMenuVisible && 'rotate-180')} />
        </button>
      )}
      {helperText && (
        <span className="absolute left-0 top-full w-full mt-1 px-4 text-xxs text-black-50">{t(helperText)}</span>
      )}
    </div>
  );
};
