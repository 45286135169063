import React, { useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { TextBasicField } from '../../TextBasicField';
import { DropdownListWithAmountsMenu } from './DropdownListWithAmountsMenu';

export interface CheckboxListItem {
  id: number;
  isChecked: boolean;
  amount: number;
  label: string;
}

interface Props {
  list: CheckboxListItem[];
  inputValue: string;
  isDropdownMenuVisible: boolean;
  onChangeFieldValue: (e: any) => void;
  onClearInputValue: () => void;
  onChangeAmount: (needId: number, amount: number) => void;
  setDropdownMenuVisibility: (v: any) => void;
}

function useComponentState(props: Props) {
  const {
    list,
    inputValue,
    isDropdownMenuVisible,
    onChangeAmount,
    onChangeFieldValue,
    onClearInputValue,
    setDropdownMenuVisibility,
  } = props;
  const ref = useRef<any>(null);

  const handleFieldFocus = () => {
    setDropdownMenuVisibility(true);
  };
  const handleDropdownToggle = () => {
    setDropdownMenuVisibility((state) => !state);
  };

  useClickAway(ref, (event) => {
    if (event.target.id !== 'button-add-needs') {
      setDropdownMenuVisibility(false);
    }
  });

  const textFieldProps = {
    value: inputValue,
    id: 'category-need-field',
    label: 'needs',
    isDropdownMenuVisible,
    clearable: true,
    hasDropdownArrow: true,
    helperText: 'helper-select-with-search',
    onChange: onChangeFieldValue,
    onFocus: handleFieldFocus,
    onClear: onClearInputValue,
    onDropdownToggle: handleDropdownToggle,
  };
  const dropdownMenuProps = {
    isOpen: isDropdownMenuVisible,
    list,
    onChangeAmount,
  };

  return {
    ref,
    textFieldProps,
    dropdownMenuProps,
  };
}

export const DropdownCheckboxListWithSearch: React.FC<Props> = (props) => {
  const { ref, textFieldProps, dropdownMenuProps } = useComponentState(props);

  return (
    <div className="relative flex-1 mb-12 md:mr-2 md:mb-0" ref={ref}>
      <TextBasicField {...textFieldProps} />
      <DropdownListWithAmountsMenu {...dropdownMenuProps} />
    </div>
  );
};
