import React, { useEffect } from 'react';

import { Pagination } from '@shared/ui/Pagination';

import { useGetTotalActiveApplicationsQuery } from '@store/api/applications.api';
import { useCustomerApplicationsParams } from './hooks/useCustomerApplicationsParams';
import { UsePagination } from '@shared/hooks/usePagination';

export function CustomerPagination({ pagination, type }: { pagination: UsePagination; type: string }) {
  const params = useCustomerApplicationsParams(pagination, type);

  const { page, ...other } = params;
  const { data } = useGetTotalActiveApplicationsQuery(other);

  useEffect(() => {
    pagination.reset();
  }, [type]);

  return (
    <Pagination
      initialPage={pagination.initialPage}
      onPageChange={pagination.onPageChange}
      pageCount={data?.totalPages || 0}
    />
  );
}
