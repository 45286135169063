import * as React from 'react';

interface IconCheckboxFormProps {
  className?: string;
  checked?: boolean;
  onClick?: () => void;
}

export const IconCheckboxForm: React.FC<IconCheckboxFormProps> = ({
  className = '',
  checked = false,
  onClick = () => {},
}) => {
  return (
    <svg
      width="24"
      height="24"
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <rect x="1" y="1" width="22" height="22" rx="5" stroke="black" strokeWidth="2" />
      <path className={checked ? '' : 'hidden'} d="M5 11L10 16.5L19 7" stroke="black" strokeWidth="2" />
    </svg>
  );
};
