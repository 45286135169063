import { useAppSelector } from '@store/hooks';
import { selectAuthUser } from '@store/slices/auth';
import { ApplicationsTotalTarget } from '../enums';
import { useCancelApplicationItemsMutation } from '@store/api/applications.api';

export const useRemoveInProgressHandler = () => {
  const user = useAppSelector(selectAuthUser);
  const [cancelApplicationItems] = useCancelApplicationItemsMutation();
  return (id: number) => {
    if (user) {
      cancelApplicationItems({ target: ApplicationsTotalTarget.Customer, itemIds: [id], state: 'in_progress' });
    }
  };
};

export const useRemoveInProgressItemsHandler = () => {
  const user = useAppSelector(selectAuthUser);
  const [cancelApplicationItems] = useCancelApplicationItemsMutation();
  return (itemIds: number[]) => {
    if (user) {
      cancelApplicationItems({ target: ApplicationsTotalTarget.Customer, itemIds, state: 'in_progress' });
    }
  };
};
