import { useMemo } from 'react';
import { usePathname } from './usePathname';

export function useLinkStateWithPathname() {
  const pathname = usePathname();
  return useMemo(
    () => ({
      prevPathname: pathname,
    }),
    [pathname],
  );
}
