import { useShops } from '@hooks/useShops';
import { HowToHelpType } from '@shared/enums';
import { useAppSelector } from '@store/hooks';
import { selectChildrenToysShop } from '@store/slices/customer/selectors';

export function useChildrenToysRulesCheckboxVisibility() {
  const childrenToysShop = useAppSelector(selectChildrenToysShop);

  const shops = useShops(HowToHelpType.ChildrenToy);

  const validValues = shops.map((shop) => shop.name);

  return validValues.includes(childrenToysShop);
}
