import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Element } from '@shared/interfaces/element.interface';

import { HowToHelpType } from '@shared/enums';
import { PrimaryDropdown } from '@shared/dropdowns/PrimaryDropdown';

import { useIntegrationConfigs } from '@hooks/useIntegrationConfigs';

interface Props extends Element {
  value: string;
  onChange: (value: string) => void;
  errorText?: string;
}

function useComponentState() {
  const { t } = useTranslation();
  const integrationConfigs = useIntegrationConfigs();
  const shops = integrationConfigs.find((config) => config.type === HowToHelpType.ChildrenToy)?.shops || [];
  const options = useMemo(() => {
    const mapper = (item: { name: string; label: string }) => ({ value: item.name, label: t(item.label) });
    return shops.map(mapper);
  }, [shops, t]);

  return {
    t,
    options,
  };
}

export const ChildToysShopDropdown: React.FC<Props> = ({ value, className, errorText, onChange }) => {
  const { t, options } = useComponentState();

  return (
    <PrimaryDropdown
      value={value}
      onChange={onChange}
      options={options}
      className={className}
      label={t('choose-toys-shop')}
      errorText={errorText && t(errorText)}
    />
  );
};
