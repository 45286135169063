import React from 'react';
import { Element } from '@shared/interfaces/element.interface';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

interface CalloutProps extends Element {
  icon: {
    optimized?: any;
    src?: string;
    className?: string;
  };
  children: React.ReactElement | string;
  className?: string;
}

export const Callout: React.FC<CalloutProps> = ({ icon = { className: '' }, className = '', children }) => {
  return (
    <div className={className}>
      <div className="flex items-center">
        {icon.optimized && (
          <GatsbyImage image={getImage(icon.optimized)} alt="icon" className={`shrink-0 ${icon.className}`} />
        )}
        {icon.src && <img alt="icon" src={icon.src} className={`shrink-0 ${icon.className}`} />}
        <div className="ml-3">{children}</div>
      </div>
    </div>
  );
};
