import { useShops } from '@hooks/useShops';
import { HowToHelpType } from '@shared/enums';
import { useAppSelector } from '@store/hooks';
import { selectApplicationItemsCategories } from '@store/slices/customer/selectors';

/**
 * TODO: bad approach, because categories related to some shop, not to type
 *
 * We need to rework it.
 */
function useShopCategories(type: HowToHelpType) {
  const shops = useShops(type);

  /**
   *  Because of that this array also has duplicates.
   */
  return shops.flatMap((shop) => shop.config.applicationCategories || []);
}

export function useItemsStoreCategories() {
  const itemsCategories = useAppSelector(selectApplicationItemsCategories);

  const foodCategories = useShopCategories(HowToHelpType.OnlineFood);
  const pharmacyCategories = useShopCategories(HowToHelpType.OnlineMedicaments);
  const fuelCategories = useShopCategories(HowToHelpType.OnlineFuel);
  const easterCategories = useShopCategories(HowToHelpType.Easter);
  const childProtectionCategories = useShopCategories(HowToHelpType.ChildrenProtected);
  const childrenToyCategories = useShopCategories(HowToHelpType.ChildrenToy);

  const hasEasterCategory = easterCategories.some((v) => itemsCategories.has(v));
  const hasChildProtectionCategory = childProtectionCategories.some((v) => itemsCategories.has(v));
  const hasChildToysCategory = childrenToyCategories.some((v) => itemsCategories.has(v));
  const hasSupermarketCategories = foodCategories.some((v) => itemsCategories.has(v));
  const hasPharmacyCategories = pharmacyCategories.some((v) => itemsCategories.has(v));
  const hasGasStationCategory = fuelCategories.some((v) => itemsCategories.has(v));
  const hasStoreCategories =
    hasSupermarketCategories ||
    hasPharmacyCategories ||
    hasGasStationCategory ||
    hasEasterCategory ||
    hasChildProtectionCategory ||
    hasChildToysCategory;

  return {
    hasStoreCategories,
    hasSupermarketCategories,
    hasPharmacyCategories,
    hasGasStationCategory,
    hasEasterCategory,
    hasChildProtectionCategory,
    hasChildToysCategory,
  };
}
