import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PrimaryDropdown } from '@shared/dropdowns/PrimaryDropdown';
import { Element } from '@shared/interfaces/element.interface';

import { useDictionaryCategoriesQuery } from '@store/api/dictionaries.api';
import { getPrettyCategoryName } from '@shared/utils/applications';

interface CategoryDropdownProps extends Element {
  value: string | null;
  onChange?: (id: number) => void;
  errorText?: string;
}

export const CategoryDropdown: React.FC<CategoryDropdownProps> = ({ value, className, errorText, onChange }) => {
  const { t } = useTranslation();

  const { data: categories = [] } = useDictionaryCategoriesQuery();

  const options = useMemo(
    () =>
      categories.map((item) => ({
        label: getPrettyCategoryName(item.name),
        value: item.id,
      })),
    [categories],
  );

  const onCategoryChange = (option: number) => {
    if (onChange) {
      onChange(option);
    }
  };

  return (
    <>
      <PrimaryDropdown
        value={value}
        onChange={onCategoryChange}
        options={options}
        className={className}
        label={t('category')}
        errorText={errorText && t(errorText)}
      />
    </>
  );
};
