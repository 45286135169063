import { useLanguage } from '@hooks/useLanguage';
import { useShops } from '@hooks/useShops';
import { HowToHelpType } from '@shared/enums';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { selectSupermarket, selectSupermarketRules } from '@store/slices/customer/selectors';
import { customerActions } from '@store/slices/customer/slice';
import React, { useCallback, useMemo } from 'react';
import { useSupermarketRulesCheckboxVisibility } from '../../hooks/useSupermarketRulesCheckboxVisibility';
import { CustomerStoreRulesCheckbox } from './CustomerStoreRulesCheckbox';

import { getRulesUrl } from './utils';

function useComponentState() {
  const language = useLanguage();
  const dispatch = useAppDispatch();
  const supermarket = useAppSelector(selectSupermarket);
  const supermarketRulesChecked = useAppSelector(selectSupermarketRules);

  const foodShops = useShops(HowToHelpType.OnlineFood);
  const selectedShop = foodShops.find((shop) => shop.name === supermarket);

  const isRulesCheckboxVisible = useSupermarketRulesCheckboxVisibility();
  const rulesLink = useMemo(() => {
    if (isRulesCheckboxVisible) {
      return getRulesUrl(selectedShop?.config.rules, language);
    }
  }, [isRulesCheckboxVisible, selectedShop, language]);

  const storeName = useMemo(() => {
    if (isRulesCheckboxVisible) {
      return selectedShop?.label;
    }
  }, [isRulesCheckboxVisible, selectedShop]);

  const handleCheckboxChange = useCallback((e) => {
    dispatch(customerActions.changeSupermarketRules(e.target.checked));
  }, []);

  const customerStoreRulesCheckboxProps = {
    name: 'supermarket',
    checked: supermarketRulesChecked,
    rulesLink,
    storeName,
    onChange: handleCheckboxChange,
    className: 'first:mt-0 mt-3',
  };

  return {
    isRulesCheckboxVisible,
    customerStoreRulesCheckboxProps,
  };
}

export const CustomerSupermarketRulesCheckbox = () => {
  const { isRulesCheckboxVisible, customerStoreRulesCheckboxProps } = useComponentState();

  if (!isRulesCheckboxVisible) return null;

  return <CustomerStoreRulesCheckbox {...customerStoreRulesCheckboxProps} />;
};
