import { useGetUserProfileQuery } from '@store/api/user.api';

export function useCanCreateApplication(): readonly [boolean, string[]] {
  const { data: profile = null } = useGetUserProfileQuery();
  if (!profile) return [true, [] as string[]] as const;

  const requiredData = [
    {
      name: 'phone-number',
      value: profile.phoneNumber,
    },
    {
      name: 'about-me',
      value: profile.description,
    },
    {
      name: 'country',
      value: profile.address?.country,
    },
    {
      name: 'region',
      value: profile.address?.region,
    },
    {
      name: 'area',
      value: profile.address?.district,
    },
    {
      name: 'locality',
      value: profile.address?.locality,
    },
    {
      name: 'street',
      value: profile.address?.streetName,
    },
  ];
  const missingData = requiredData.filter((item) => !item.value);

  return [missingData.length === 0, missingData.map((item) => item.name)] as const;
}
