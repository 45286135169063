import { ChildProtectionShopDropdown } from '@shared/ui/dropdowns/ChildProtectionShopDropdown';
import { ChildToysShopDropdown } from '@shared/ui/dropdowns/ChildToysShopDropdown';
import { EasterShopDropdown } from '@shared/ui/dropdowns/EasterShopDropdown';
import { GasStationDropdown } from '@shared/ui/dropdowns/GasStationDropdown';
import { PharmacyDropdown } from '@shared/ui/dropdowns/PharmacyDropdown';
import { SupermarketDropdown } from '@shared/ui/dropdowns/SupermarketsDropdown';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import {
  selectGasStation,
  selectPharmacy,
  selectSupermarket,
  selectEasterShop,
  selectChildProtectionShop,
  selectChildrenToysShop,
} from '@store/slices/customer/selectors';
import { customerActions } from '@store/slices/customer/slice';
import { CustomerChildProtectionRulesCheckbox } from '@widgets/cabinet/customer/forms/CreateApplication/CustomerChildProtectionRulesCheckbox';
import { CustomerChildrenToyRulesCheckbox } from '@widgets/cabinet/customer/forms/CreateApplication/CustomerChildrenToyRulesCheckbox';
import { CustomerEasterRulesCheckbox } from '@widgets/cabinet/customer/forms/CreateApplication/CustomerEasterRulesCheckbox';
import { CustomerGasStationRulesCheckbox } from '@widgets/cabinet/customer/forms/CreateApplication/CustomerGasStationRulesCheckbox';
import { CustomerPharmacyRulesCheckbox } from '@widgets/cabinet/customer/forms/CreateApplication/CustomerPharmacyRulesCheckbox';
import { CustomerSupermarketRulesCheckbox } from '@widgets/cabinet/customer/forms/CreateApplication/CustomerSupermarketRulesCheckbox';
import { useChildProtectionRulesCheckboxVisibility } from '@widgets/cabinet/customer/hooks/useChildProtectionRulesCheckboxVisibility';
import { useChildrenToysRulesCheckboxVisibility } from '@widgets/cabinet/customer/hooks/useChildrenToysRulesCheckboxVisibility';
import { useEasterRulesCheckboxVisibility } from '@widgets/cabinet/customer/hooks/useEasterRulesCheckboxVisibility';
import { useGasStationRulesCheckboxVisibility } from '@widgets/cabinet/customer/hooks/useGasStationRulesCheckboxVisibility';
import { useItemsStoreCategories } from '@widgets/cabinet/customer/hooks/useItemsStoreCategories';
import { usePharmacyRulesCheckboxVisibility } from '@widgets/cabinet/customer/hooks/usePharmacyRulesCheckboxVisibility';
import { useSupermarketRulesCheckboxVisibility } from '@widgets/cabinet/customer/hooks/useSupermarketRulesCheckboxVisibility';
import clsx from 'clsx';
import React from 'react';

interface Props {}

function useComponentState() {
  const dispatch = useAppDispatch();
  const supermarketValue = useAppSelector(selectSupermarket);
  const pharmacyValue = useAppSelector(selectPharmacy);
  const gasStationValue = useAppSelector(selectGasStation);
  const easterShopValue = useAppSelector(selectEasterShop);
  const childProtectionShopValue = useAppSelector(selectChildProtectionShop);
  const childToysShopValue = useAppSelector(selectChildrenToysShop);

  const {
    hasSupermarketCategories,
    hasPharmacyCategories,
    hasGasStationCategory,
    hasEasterCategory,
    hasChildProtectionCategory,
    hasChildToysCategory,
  } = useItemsStoreCategories();
  const isSupermarketRulesCheckboxVisible = useSupermarketRulesCheckboxVisibility();
  const isPharmacyRulesCheckboxVisible = usePharmacyRulesCheckboxVisibility();
  const isGasStationRulesCheckboxVisible = useGasStationRulesCheckboxVisibility();
  const isEasterRulesCheckboxVisible = useEasterRulesCheckboxVisibility();
  const isChildProtectionCheckboxVisible = useChildProtectionRulesCheckboxVisibility();
  const isChildToysCheckboxVisible = useChildrenToysRulesCheckboxVisibility();

  const isRulesCheckboxesVisible =
    isSupermarketRulesCheckboxVisible ||
    isPharmacyRulesCheckboxVisible ||
    isGasStationRulesCheckboxVisible ||
    isEasterRulesCheckboxVisible ||
    isChildProtectionCheckboxVisible ||
    isChildToysCheckboxVisible;

  const handleSupermaketChange = (option: string) => dispatch(customerActions.setSupermarket(option));
  const handlePharmacyChange = (option: string) => dispatch(customerActions.setPharmacy(option));
  const handleGasStationChange = (option: string) => dispatch(customerActions.setGasStation(option));
  const handleEasterChange = (option: string) => dispatch(customerActions.setEasterShop(option));
  const handleChildProtectedShopChange = (option: string) => dispatch(customerActions.setChildProtectedShop(option));
  const handleChildToysShopChange = (option: string) => dispatch(customerActions.setChildToysShop(option));

  return {
    supermarketValue,
    pharmacyValue,
    gasStationValue,
    childToysShopValue,
    easterValue: easterShopValue,
    hasSupermarketCategories,
    childProtectionShopValue,
    hasPharmacyCategories,
    hasChildToysCategory,
    hasGasStationCategory,
    hasEasterCategory,
    isRulesCheckboxesVisible,
    hasChildProtectionCategory,
    handleSupermaketChange,
    handlePharmacyChange,
    handleGasStationChange,
    handleEasterChange,
    handleChildProtectedShopChange,
    handleChildToysShopChange,
  };
}

export const ModalBodyCustomerChooseStore: React.FC<Props> = () => {
  const {
    supermarketValue,
    pharmacyValue,
    gasStationValue,
    childProtectionShopValue,
    easterValue,
    hasSupermarketCategories,
    hasPharmacyCategories,
    hasGasStationCategory,
    hasEasterCategory,
    isRulesCheckboxesVisible,
    hasChildProtectionCategory,
    hasChildToysCategory,
    childToysShopValue,
    handleSupermaketChange,
    handlePharmacyChange,
    handleGasStationChange,
    handleEasterChange,
    handleChildProtectedShopChange,
    handleChildToysShopChange,
  } = useComponentState();

  return (
    <div className="mt-10">
      {hasChildToysCategory && (
        <ChildToysShopDropdown value={childToysShopValue} onChange={handleChildToysShopChange} />
      )}
      {hasChildProtectionCategory && (
        <ChildProtectionShopDropdown value={childProtectionShopValue} onChange={handleChildProtectedShopChange} />
      )}
      {hasEasterCategory && <EasterShopDropdown value={easterValue} onChange={handleEasterChange} />}
      {hasSupermarketCategories && (
        <SupermarketDropdown value={supermarketValue} onChange={handleSupermaketChange} className="mt-10" />
      )}
      {hasPharmacyCategories && (
        <PharmacyDropdown value={pharmacyValue} onChange={handlePharmacyChange} className="mt-10" />
      )}
      {hasGasStationCategory && (
        <GasStationDropdown value={gasStationValue} onChange={handleGasStationChange} className="mt-10" />
      )}
      <div className={clsx(isRulesCheckboxesVisible && 'mt-9')}>
        <CustomerSupermarketRulesCheckbox />
        <CustomerPharmacyRulesCheckbox />
        <CustomerGasStationRulesCheckbox />
        <CustomerEasterRulesCheckbox />
        <CustomerChildProtectionRulesCheckbox />
        <CustomerChildrenToyRulesCheckbox />
      </div>
    </div>
  );
};
