import { useLanguage } from '@hooks/useLanguage';
import { useShops } from '@hooks/useShops';
import { HowToHelpType } from '@shared/enums';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { selectPharmacy, selectPharmacyRules } from '@store/slices/customer/selectors';
import { customerActions } from '@store/slices/customer/slice';
import React, { useCallback, useMemo } from 'react';
import { usePharmacyRulesCheckboxVisibility } from '../../hooks/usePharmacyRulesCheckboxVisibility';
import { CustomerStoreRulesCheckbox } from './CustomerStoreRulesCheckbox';
import { getRulesUrl } from './utils';

function useComponentState() {
  const language = useLanguage();
  const dispatch = useAppDispatch();
  const pharmacy = useAppSelector(selectPharmacy);
  const pharmacyRulesChecked = useAppSelector(selectPharmacyRules);

  const shops = useShops(HowToHelpType.OnlineMedicaments);
  const selectedShop = shops.find((shop) => shop.name === pharmacy);

  const isRulesCheckboxVisible = usePharmacyRulesCheckboxVisibility();
  const rulesLink = useMemo(() => {
    if (isRulesCheckboxVisible) {
      return getRulesUrl(selectedShop?.config.rules, language);
    }
  }, [isRulesCheckboxVisible, selectedShop, language]);

  const storeName = useMemo(() => {
    if (isRulesCheckboxVisible) {
      return selectedShop?.label;
    }
  }, [isRulesCheckboxVisible, selectedShop]);

  const handleCheckboxChange = useCallback((e) => {
    dispatch(customerActions.changePharmacyRules(e.target.checked));
  }, []);

  const customerStoreRulesCheckboxProps = {
    name: 'pharmacy',
    checked: pharmacyRulesChecked,
    rulesLink,
    storeName,
    onChange: handleCheckboxChange,
    className: 'first:mt-0 mt-3',
  };

  return {
    isRulesCheckboxVisible,
    customerStoreRulesCheckboxProps,
  };
}

export const CustomerPharmacyRulesCheckbox = () => {
  const { isRulesCheckboxVisible, customerStoreRulesCheckboxProps } = useComponentState();

  if (!isRulesCheckboxVisible) return null;

  return <CustomerStoreRulesCheckbox {...customerStoreRulesCheckboxProps} />;
};
