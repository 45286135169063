import React from 'react';
import clsx from 'clsx';

import { Element } from '@shared/interfaces/element.interface';

export interface ChipProps extends Element {
  text: string;

  icon?: React.ReactNode;

  onClick?: () => void;
}

export const Chip: React.FC<ChipProps> = ({ text, onClick, icon, className }) => {
  return (
    <div
      className={clsx(
        'inline-flex justify-center min-w-[140px] py-2 rounded-[18px] bg-solitude space-x-2 text-xxs',
        className,
      )}
      onClick={onClick}
    >
      {icon}
      <span>{text}</span>
    </div>
  );
};
