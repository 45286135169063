import { useShops } from '@hooks/useShops';
import { HowToHelpType } from '@shared/enums';
import { useAppSelector } from '@store/hooks';
import { selectEasterShop } from '@store/slices/customer/selectors';

export function useEasterRulesCheckboxVisibility() {
  const easterShop = useAppSelector(selectEasterShop);

  const shops = useShops(HowToHelpType.Easter);
  const validValues = shops.map((shop) => shop.name);

  return validValues.includes(easterShop);
}
