import { CheckboxNotFormField } from '@shared/ui/CheckboxNotFormField';
import { OuterLink } from '@shared/ui/GenericLink';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const CustomerStoreRulesCheckbox = (props) => {
  const { t } = useTranslation();
  const { name, checked, rulesLink, storeName, onChange, className } = props;

  return (
    <CheckboxNotFormField
      name={name}
      isChecked={checked}
      className={className}
      label={
        <>
          {t('agree-with')}{' '}
          <OuterLink className="text-shamrock hover:underline" to={rulesLink}>
            {t('certification-rules')}
          </OuterLink>{' '}
          ({t(storeName)})
        </>
      }
      onChange={onChange}
    />
  );
};
