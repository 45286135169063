import React from 'react';

import { Banner } from '@shared/ui/banner';
import { useNavigate } from '@hooks/useNavigate';

import { useTranslation } from 'react-i18next';

import { useCanCreateApplication } from './hooks/useCanCreateApplication';

export const CabinetCustomerCanCreateApplicationBanner = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [canCreate, missingData] = useCanCreateApplication();

  if (canCreate) return null;

  return (
    <Banner action={t('go-to-profile')} onActionClick={() => navigate('/profile')}>
      {t('conditions-to-create-application')} {missingData.map((item) => `"${t(item)}"`).join(', ')}
    </Banner>
  );
};
