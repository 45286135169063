import { useCancelCustomerApplicationItems } from '@shared/hooks/useCancelCustomerApplicationItems';
import { Loader } from '@shared/ui/loader/Loader';
import { LoaderWrapper } from '@shared/ui/loader/LoaderWrapper';
import React, { useState } from 'react';
import { ApplicationCardAddress } from './ApplicationCardAddress';
import { ApplicationCardAmount } from './ApplicationCardAmount';
import { ApplicationCardApplicantCategories } from './ApplicationCardApplicantCategories';
import { ApplicationCardCollapseController } from './ApplicationCardCollapseController';
import { ApplicationCardNeedsHolder } from './ApplicationCardNeedsHolder';
import { CustomerCurrentApplicationCardItems } from './CustomerCurrentApplicationCardItems';
import { DenyApplicationButton } from './DenyApplicationButton';
import { ApplicationCardProps } from './types';

type CustomerCurrentApplicationCardProps = ApplicationCardProps & {
  loading?: boolean;
};

function useIsCollapsed() {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const toggleCollapsedHandler = () => setIsCollapsed((isCollapsed) => !isCollapsed);

  return { isCollapsed, toggleCollapsedHandler } as const;
}

function usePerformCardState(props: CustomerCurrentApplicationCardProps, actions: any) {
  const { isCollapsed, toggleCollapsedHandler } = useIsCollapsed();

  return {
    applicationId: props.id,
    purchase: props.purchase,
    view: props.view,
    applicationCardAddressProps: {
      address: props.customer.address,
    },
    applicationCardAmountProps: {
      amount: props.items.length,
      label: 'total-needs',
    },
    applicationCardItemsProps: {
      items: props.items,
      view: props.view,
      onRemove: (id: number) => actions.cancelItems([id]),
    },
    applicationCardWhoNeedsProps: {
      customer: props.customer,
      view: props.view,
    },
    collapsedProps: {
      isCollapsed,
      toggleCollapsedHandler,
    },
    denyButtonProps: {
      needConfirm: true,
      onClick: () => actions.cancelItems(props.items.map((item) => item.id)),
    },
  } as const;
}

export const CustomerCurrentApplicationCard = (props: CustomerCurrentApplicationCardProps) => {
  const [cancelItems, { isLoading }] = useCancelCustomerApplicationItems();

  const {
    view,
    denyButtonProps,
    applicationCardAddressProps,
    applicationCardAmountProps,
    applicationCardItemsProps,
    applicationCardWhoNeedsProps,
    collapsedProps,
  } = usePerformCardState(props, { cancelItems });

  return (
    <div className="relative p-[24px] lg:p-[32px] bg-white border-hawkes-blue border-2 rounded-lg">
      {isLoading && (
        <LoaderWrapper className="rounded-lg">
          <Loader className="h-32" />
        </LoaderWrapper>
      )}
      <div className="flex items-start justify-between w-100">
        <ApplicationCardAddress {...applicationCardAddressProps} className="mb-4" />
        <ApplicationCardCollapseController
          className="hidden lg:flex"
          onClick={collapsedProps.toggleCollapsedHandler}
          isCollapsed={collapsedProps.isCollapsed}
          view={view}
        />
      </div>
      <div className="flex flex-col md:flex-row md:justify-between">
        <ApplicationCardAmount {...applicationCardAmountProps} />
        <DenyApplicationButton className="my-3 mx-auto md:my-0 md:mx-0 items-center" {...denyButtonProps} />
      </div>
      <div className="flex justify-center">
        <ApplicationCardCollapseController
          className="flex mx-auto lg:hidden"
          onClick={collapsedProps.toggleCollapsedHandler}
          isCollapsed={collapsedProps.isCollapsed}
          view={view}
        />
      </div>
      <ApplicationCardNeedsHolder isCollapsed={collapsedProps.isCollapsed}>
        <CustomerCurrentApplicationCardItems {...applicationCardItemsProps} />
        <ApplicationCardApplicantCategories {...applicationCardWhoNeedsProps} />
      </ApplicationCardNeedsHolder>
    </div>
  );
};
