import { useGetIconOptimized } from '@shared/hooks/useGetIconOptimized';
import { ModalBasic, ModalBasicProps } from '@shared/ui/modal/ModalBasic';
import React from 'react';
import { ModalBodyGratitudeCreateApplication } from './bodies/ModalBodyGratitudeCreateApplication';

type ModalGratitudeHandToHandProps = Pick<ModalBasicProps, 'isVisible' | 'onClose' | 'onApprove'>;

export const ModalGratitudeCreateApplication: React.FC<ModalGratitudeHandToHandProps> = ({
  isVisible,
  onClose,
  onApprove,
}) => {
  const iconOptimized = useGetIconOptimized('circle-man-with-laptop');
  const imageHeading = {
    optimized: iconOptimized,
  };

  return (
    <ModalBasic
      hasHeaderCloseBtn={false}
      isVisible={isVisible}
      title="created-order"
      imageHeading={imageHeading}
      secondaryBtnLabel="stay"
      approveBtnLabel="go"
      onClose={onClose}
      onApprove={onApprove}
    >
      <ModalBodyGratitudeCreateApplication />
    </ModalBasic>
  );
};
