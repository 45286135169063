import React from 'react';
import { useTranslation } from 'react-i18next';

interface EmptyApplicationCardProps {
  text?: string;
}

export const EmptyApplicationCard: React.FC<EmptyApplicationCardProps> = ({
  text = 'there-are-no-such-applications-yet',
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-center p-[24px] lg:p-[32px] h-[216px] bg-white border-hawkes-blue border-2 rounded-lg">
      <span className="text-md italic text-gray">{t(text)}</span>
    </div>
  );
};
