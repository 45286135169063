import React from 'react';
import { ApplicationCardRemovableItem } from './ApplicationCardRemovableItem';
import { ApplicationItem } from './types';
import { ApplicationView } from '@shared/enums';
import { isDefaultView } from '@shared/utils/applications';
import { ApplicationCardItemDetails } from './ApplicationCardItemDetails';
import { useRemoveHandToHandItemHandler } from './useRemoveHandToHandHandler';

type CustomerCurrentApplicationViewCardItemsProps = React.HTMLAttributes<HTMLDivElement> & {
  items: ApplicationItem[];
};

function useCustomerHandToHandApplicationCardItemsState({ items }: CustomerCurrentApplicationViewCardItemsProps) {
  const onRemove = useRemoveHandToHandItemHandler();
  return {
    items,
    onRemove,
  } as const;
}

export const CustomerHandToHandApplicationCardItems = (props: CustomerCurrentApplicationViewCardItemsProps) => {
  const { items, onRemove } = useCustomerHandToHandApplicationCardItemsState(props);

  return (
    <div className="lg:col-span-3">
      {items.map((item) => (
        <ApplicationCardRemovableItem key={item.id} {...item} onRemove={onRemove} needConfirm />
      ))}
    </div>
  );
};
