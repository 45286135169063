import { useShops } from '@hooks/useShops';
import { HowToHelpType } from '@shared/enums';
import { useAppSelector } from '@store/hooks';
import {
  selectEasterShop,
  selectEasterRules,
  selectGasStation,
  selectGasStationRules,
  selectPharmacy,
  selectPharmacyRules,
  selectSupermarket,
  selectSupermarketRules,
  selectChildProtectionShop,
  selectChildProtectionRules,
  selectChildrenToysShop,
  selectChildrenToysRules,
} from '@store/slices/customer/selectors';
import { useItemsStoreCategories } from '@widgets/cabinet/customer/hooks/useItemsStoreCategories';

export function useChooseStoreValidation() {
  const supermarkets = useShops(HowToHelpType.OnlineFood);
  const pharmacies = useShops(HowToHelpType.OnlineMedicaments);
  const gasStations = useShops(HowToHelpType.OnlineFuel);
  const easterShops = useShops(HowToHelpType.Easter);
  const childrenProtectedShops = useShops(HowToHelpType.ChildrenProtected);
  const childrenToyShops = useShops(HowToHelpType.ChildrenToy);

  const {
    hasStoreCategories,
    hasSupermarketCategories,
    hasPharmacyCategories,
    hasGasStationCategory,
    hasEasterCategory,
    hasChildProtectionCategory,
    hasChildToysCategory,
  } = useItemsStoreCategories();

  const selectedSupermarket = useAppSelector(selectSupermarket);
  const selectedPharmacy = useAppSelector(selectPharmacy);
  const selectedGasStation = useAppSelector(selectGasStation);
  const selectedEasterShop = useAppSelector(selectEasterShop);
  const selectedChildProtectionShop = useAppSelector(selectChildProtectionShop);
  const selectedChildrenToysShop = useAppSelector(selectChildrenToysShop);

  const supermarketRulesChecked = useAppSelector(selectSupermarketRules);
  const pharmacyRulesChecked = useAppSelector(selectPharmacyRules);
  const gasStationRulesChecked = useAppSelector(selectGasStationRules);
  const easterShopRulesChecked = useAppSelector(selectEasterRules);
  const childrenProtectionShopRulesChecked = useAppSelector(selectChildProtectionRules);
  const childrenToysShopRulesChecked = useAppSelector(selectChildrenToysRules);

  const isSupermarketPositive = supermarkets.some((item) => item.name === selectedSupermarket);
  const isPharmacyPositive = pharmacies.some((item) => item.name === selectedPharmacy);
  const isGasStationPositive = gasStations.some((item) => item.name === selectedGasStation);
  const isEasterShopPositive = easterShops.some((item) => item.name === selectedEasterShop);
  const isChildProtectionShopPositive = childrenProtectedShops.some(
    (item) => item.name === selectedChildProtectionShop,
  );
  const isChildrenToysShopPositive = childrenToyShops.some((item) => item.name === selectedChildrenToysShop);

  const isSelectedPharmacyValid = hasPharmacyCategories ? Boolean(selectedPharmacy) : true;
  const isSelectedGasStationValid = hasGasStationCategory ? Boolean(selectedGasStation) : true;
  const isSelectedEasterShopValid = hasEasterCategory ? Boolean(selectedEasterShop) : true;
  const isChildProtectionShopValid = hasChildProtectionCategory ? Boolean(selectedChildProtectionShop) : true;
  const isChildToysShopValid = hasChildToysCategory ? Boolean(selectedChildrenToysShop) : true;
  const isSelectedSupermarketValid = hasSupermarketCategories ? Boolean(selectedSupermarket) : true;

  const isSupermarketRulesValid = isSupermarketPositive ? supermarketRulesChecked : true;
  const isPharmacyRulesValid = isPharmacyPositive ? pharmacyRulesChecked : true;
  const isGasStationRulesValid = isGasStationPositive ? gasStationRulesChecked : true;
  const isEasterShopRulesValid = isEasterShopPositive ? easterShopRulesChecked : true;
  const isChildrenProtectionShopRulesValid = isChildProtectionShopPositive ? childrenProtectionShopRulesChecked : true;
  const isChildrenToysShopRulesValid = isChildrenToysShopPositive ? childrenToysShopRulesChecked : true;

  const isChooseStoreFormValid =
    isSelectedSupermarketValid &&
    isSelectedPharmacyValid &&
    isSelectedGasStationValid &&
    isSelectedEasterShopValid &&
    isSupermarketRulesValid &&
    isPharmacyRulesValid &&
    isGasStationRulesValid &&
    isEasterShopRulesValid &&
    isChildToysShopValid &&
    isChildProtectionShopValid &&
    isChildrenProtectionShopRulesValid &&
    isChildrenToysShopRulesValid;

  return isChooseStoreFormValid;
}
