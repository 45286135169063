import React from 'react';
import { CompleteApplicationCard } from '@widgets/cabinet/applicationCard/CompleteApplicationCard';

import { Application } from '@widgets/cabinet/applicationCard/types';
import { CustomerCurrentApplicationCard } from '@widgets/cabinet/applicationCard/CustomerCurrentApplicationCard';
import { CustomerProcessedApplicationCard } from '@widgets/cabinet/applicationCard/CustomerProcessedApplicationCard';
import { CustomerHandToHandApplicationCard } from '@widgets/cabinet/applicationCard/CustomerHandToHandApplicationCard';
import { EmptyApplicationCard } from '@widgets/cabinet/applicationCard/EmptyApplicationCard';
import { CustomerInProgressApplicationCard } from '@widgets/cabinet/applicationCard/CustomerInProgressApplicationCard';

export function CustomerApplications({ items, type }: { items: Application[]; type: string }) {
  return (
    <div className="flex flex-col space-y-6">
      {items.length <= 0 && <EmptyApplicationCard />}
      {items.map((item) => {
        if (type === 'new') {
          return <CustomerCurrentApplicationCard key={item.id} {...item} />;
        }

        if (type === 'processed') {
          return <CustomerProcessedApplicationCard key={item.id} {...item} />;
        }

        if (type === 'in_progress') {
          return <CustomerInProgressApplicationCard key={item.id} {...item} />;
        }
        if (type === 'hand_to_hand') {
          return <CustomerHandToHandApplicationCard key={item.id} {...item} />;
        }

        if (type === 'completed') {
          return <CompleteApplicationCard key={item.id} {...item} compact />;
        }

        return null;
      })}
    </div>
  );
}
