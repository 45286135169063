import React from 'react';

import { ModalBasic, ModalBasicProps } from '@shared/ui/modal/ModalBasic';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { selectModal } from '@store/slices/customer/selectors';
import { customerActions } from '@store/slices/customer/slice';
import { ModalBodyCustomerChooseStore } from './bodies/ModalBodyCustomerChooseStore';
import { useChooseStoreValidation } from './hooks/useChooseStoreValidation';

type Props = Pick<ModalBasicProps, 'onApprove'> & { isLoading: boolean };

function useComponentState(props: Props) {
  const dispatch = useAppDispatch();
  const activeModal = useAppSelector(selectModal);

  const handleCloseModal = () => {
    dispatch(customerActions.clearChooseStoreState());
  };

  const isChooseStoreFormValid = useChooseStoreValidation();

  const isApproveBtnDisabled = !isChooseStoreFormValid || props.isLoading;

  return {
    isModalVisible: activeModal === 'customer-choose-store',
    isApproveBtnDisabled,
    handleCloseModal,
    ...props,
  };
}

export const ModalCustomerChooseStore: React.FC<Props> = (props) => {
  const { isModalVisible, isApproveBtnDisabled, onApprove, handleCloseModal } = useComponentState(props);

  return (
    <ModalBasic
      isVisible={isModalVisible}
      title="choose-purchase-store"
      secondaryBtnLabel="cancel-2"
      approveBtnLabel="select"
      isApproveBtnDisabled={isApproveBtnDisabled}
      onClose={handleCloseModal}
      onApprove={onApprove}
    >
      <ModalBodyCustomerChooseStore />
    </ModalBasic>
  );
};
