import { useCallback, useState } from 'react';

export function useToggleState(initialState = false) {
  const [state, setState] = useState<boolean>(initialState);
  const toggleState = useCallback(() => {
    setState((state) => !state);
  }, [state]);

  return [state, toggleState] as const;
}
