import { useShops } from '@hooks/useShops';
import { HowToHelpType } from '@shared/enums';
import { useAppSelector } from '@store/hooks';
import { selectChildProtectionShop } from '@store/slices/customer/selectors';

export function useChildProtectionRulesCheckboxVisibility() {
  const childProtectedShop = useAppSelector(selectChildProtectionShop);

  const shops = useShops(HowToHelpType.ChildrenProtected);
  const validValues = shops.map((shop) => shop.name);

  return validValues.includes(childProtectedShop);
}
