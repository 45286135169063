import { useLanguage } from '@hooks/useLanguage';
import { useShops } from '@hooks/useShops';
import { HowToHelpType } from '@shared/enums';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { selectGasStation, selectGasStationRules } from '@store/slices/customer/selectors';
import { customerActions } from '@store/slices/customer/slice';
import React, { useCallback, useMemo } from 'react';
import { useGasStationRulesCheckboxVisibility } from '../../hooks/useGasStationRulesCheckboxVisibility';
import { CustomerStoreRulesCheckbox } from './CustomerStoreRulesCheckbox';
import { getRulesUrl } from './utils';

function useComponentState() {
  const language = useLanguage();
  const dispatch = useAppDispatch();
  const gasStation = useAppSelector(selectGasStation);
  const gasStationRulesChecked = useAppSelector(selectGasStationRules);

  const shops = useShops(HowToHelpType.OnlineFuel);
  const selectedShop = shops.find((shop) => shop.name === gasStation);
  const isRulesCheckboxVisible = useGasStationRulesCheckboxVisibility();
  const rulesLink = useMemo(() => {
    if (isRulesCheckboxVisible) {
      return getRulesUrl(selectedShop?.config.rules, language);
    }
  }, [isRulesCheckboxVisible, selectedShop, language]);

  const gasStationName = useMemo(() => {
    if (isRulesCheckboxVisible) {
      return selectedShop?.label;
    }
  }, [isRulesCheckboxVisible, selectedShop]);

  const handleCheckboxChange = useCallback((e) => {
    dispatch(customerActions.changeGasStationRules(e.target.checked));
  }, []);

  const customerStoreRulesCheckboxProps = {
    name: 'gas-station',
    checked: gasStationRulesChecked,
    rulesLink,
    storeName: gasStationName,
    onChange: handleCheckboxChange,
    className: 'first:mt-0 mt-3',
  };

  return {
    isRulesCheckboxVisible,
    customerStoreRulesCheckboxProps,
  };
}

export const CustomerGasStationRulesCheckbox = () => {
  const { isRulesCheckboxVisible, customerStoreRulesCheckboxProps } = useComponentState();

  if (!isRulesCheckboxVisible) return null;

  return <CustomerStoreRulesCheckbox {...customerStoreRulesCheckboxProps} />;
};
