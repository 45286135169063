import React from 'react';
import { useTranslation } from 'react-i18next';

export const ModalBodyGratitudeCreateApplication = () => {
  const { t } = useTranslation();

  return (
    <p className="text-xs font-light">
      {t('volunteers-will-help-you')}{' '}
      <b className="font-medium">
        “{t('wait-for-help')}”. {t('go')}
      </b>{' '}
      <b className="font-medium">{t('or-stay')}</b>
    </p>
  );
};
