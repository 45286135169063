import { ModalBasic, ModalBasicProps } from '@shared/ui/modal/ModalBasic';
import React from 'react';

type ModalConfirmActionProps = Pick<ModalBasicProps, 'isVisible' | 'onClose' | 'onApprove' | 'title'>;

export const ModalConfirmAction: React.FC<ModalConfirmActionProps> = ({ isVisible, title, onClose, onApprove }) => {
  return (
    <ModalBasic
      isVisible={isVisible}
      title={title}
      secondaryBtnLabel="cancel-2"
      approveBtnLabel="confirm"
      onClose={onClose}
      onApprove={onApprove}
    ></ModalBasic>
  );
};
