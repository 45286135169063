import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PrimaryDropdown } from '@shared/dropdowns/PrimaryDropdown';
import { Element } from '@shared/interfaces/element.interface';
import { HowToHelpType, PurchaseStoreType } from '@shared/enums';

import { useIntegrationConfigs } from '@hooks/useIntegrationConfigs';

interface Props extends Element {
  value: string;

  onChange: (value: string) => void;

  errorText?: string;
}

function useComponentState() {
  const { t } = useTranslation();

  const integrationConfigs = useIntegrationConfigs();
  const foodShops = integrationConfigs.find((config) => config.type === HowToHelpType.OnlineFood)?.shops || [];

  const supermarketOptions = useMemo(
    () =>
      foodShops.map((item) => ({
        value: item.name,
        label: t(item.label),
      })),
    // .concat({ value: PurchaseStoreType.Default, label: t('supermarkets-missing') }),
    [foodShops, t],
  );

  return {
    t,
    supermarketOptions,
  };
}

export const SupermarketDropdown: React.FC<Props> = ({ value, className, errorText, onChange }) => {
  const { t, supermarketOptions } = useComponentState();

  return (
    <PrimaryDropdown
      value={value}
      onChange={onChange}
      options={supermarketOptions}
      className={className}
      label={t('choose-supermarket')}
      errorText={errorText && t(errorText)}
    />
  );
};
