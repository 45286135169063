import React from 'react';
import { useTranslation } from 'react-i18next';

import { CabinetCustomer } from '@widgets/cabinet/customer/CabinetCustomer';
import { CabinetCustomerBanners } from '@widgets/cabinet/customer/CabinetCustomerBanners';

const CabinetCustomerPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <CabinetCustomerBanners />

      <div className="max-w-[1400px] mx-auto px-4 pt-6 pb-8 lg:pt-12 lg:pb-28">
        <h1 className="text-2xl md:text-4xl">{t('application-for-aid')}</h1>
        <CabinetCustomer />
      </div>
    </>
  );
};

export default CabinetCustomerPage;
