import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Element } from '@shared/interfaces/element.interface';

import { HowToHelpType } from '@shared/enums';
import { PrimaryDropdown } from '@shared/dropdowns/PrimaryDropdown';

import { useIntegrationConfigs } from '@hooks/useIntegrationConfigs';

interface Props extends Element {
  value: string;
  onChange: (value: string) => void;
  errorText?: string;
}

function useComponentState() {
  const { t } = useTranslation();
  const integrationConfigs = useIntegrationConfigs();
  const shops = integrationConfigs.find((config) => config.type === HowToHelpType.ChildrenProtected)?.shops || [];

  const options = useMemo(
    () =>
      shops.map((item) => ({
        value: item.name,
        label: t(item.label),
      })),
    [shops, t],
  );

  return {
    t,
    options,
  };
}

export const ChildProtectionShopDropdown: React.FC<Props> = ({ value, className, errorText, onChange }) => {
  const { t, options } = useComponentState();

  return (
    <PrimaryDropdown
      value={value}
      onChange={onChange}
      options={options}
      className={className}
      label={t('choose-supermarket')}
      errorText={errorText && t(errorText)}
    />
  );
};
