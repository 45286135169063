import iconCircleWithCheckmark from '@assets/icons/circle-with-checkmark.png';
import { ModalBasic } from '@shared/ui/modal/ModalBasic';
import { useAppDispatch } from '@store/hooks';
import { customerActions } from '@store/slices/customer/slice';

import { ModalNames } from '@shared/enums';
import { ChooseCategoryNeeds } from '@widgets/cabinet/customer/ChooseCategoryNeeds';
import { useCustomerModalVisbility } from '@widgets/cabinet/customer/hooks/useCustomerModalVisbility';
import React from 'react';

function useComponentState() {
  const isAddNeedsModalVisible = useCustomerModalVisbility(ModalNames.AddCustomerNeeds);
  const dispatch = useAppDispatch();
  const handleClose = () => {
    dispatch(customerActions.setModal(null));
  };

  return {
    isModalVisible: isAddNeedsModalVisible,
    handleClose,
  };
}

export const ModalChooseCustomerNeeds: React.FC = () => {
  const { isModalVisible, handleClose } = useComponentState();

  return (
    <ModalBasic
      imgSrc={iconCircleWithCheckmark}
      isVisible={isModalVisible}
      title={'choose-needs-amounts'}
      hasModalFooter={false}
      onClose={handleClose}
      maxWidthSize="medium"
    >
      <ChooseCategoryNeeds />
    </ModalBasic>
  );
};
