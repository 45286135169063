import { useDictionaryCategoriesQuery } from '@store/api/dictionaries.api';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { selectApplicationItems, selectCategoryId } from '@store/slices/customer/selectors';
import { customerActions } from '@store/slices/customer/slice';
import { ApplicationItem } from '@widgets/cabinet/applicationCard/types';

export function useGetCategoryNeedsSubmitButtonProps(categoryNeeds: any, handleClearInputValue: () => void) {
  const categoryId = useAppSelector(selectCategoryId);
  const previousNeeds = useAppSelector(selectApplicationItems);
  const dispatch = useAppDispatch();
  const { data: categories = [], isLoading, isFetching } = useDictionaryCategoriesQuery();
  const handleAddNeeds = (needs: ApplicationItem[]) => {
    dispatch(customerActions.addApplicationItems(needs));
    dispatch(customerActions.setModal(null));
  };

  const isNeedsAmountChanged = checkHasNeedsAmountChanged(categoryNeeds, previousNeeds);
  const isButtonDisabled = !categoryNeeds.length || !isNeedsAmountChanged || isLoading || isFetching;

  const handleClick = (e) => {
    e.stopPropagation();
    const category = categories.find((item) => item.id === categoryId);
    const updatedNeeds = JSON.parse(JSON.stringify(previousNeeds));
    categoryNeeds.forEach((need) => {
      const isNeedInPreviousList = previousNeeds.some((v) => v.id === need.id);
      if (isNeedInPreviousList) {
        const objIndex = previousNeeds.findIndex((v) => v.id === need.id);
        const previousNeed = updatedNeeds[objIndex];

        if (need.amount > 0) {
          if (previousNeed.amount === need.amount) {
            return;
          }
          updatedNeeds[objIndex].amount = need.amount;
        } else {
          updatedNeeds.splice(objIndex, 1);
        }
      } else {
        if (need.amount === 0) {
          return;
        }
        const categoryItem = category!.items.find((item) => item.id === parseInt(need.id, 10));
        const newItem = {
          id: categoryItem!.id,
          name: categoryItem!.name,
          amount: parseFloat(need.amount),
          state: 'new',
          unit: categoryItem!.unit,
          category: {
            id: category?.id,
            name: category?.name,
          },
        };

        updatedNeeds.push(newItem);
      }
    });

    updatedNeeds.sort((a, b) => a.category.id - b.category.id);

    handleAddNeeds(updatedNeeds);
    handleClearInputValue();
  };

  return {
    disabled: isButtonDisabled,
    onClick: handleClick,
  };
}

function checkHasNeedsAmountChanged(categoryNeeds, previousNeeds) {
  if (previousNeeds.length) {
    return categoryNeeds.some((v) => {
      const previousNeedIndex = previousNeeds.findIndex((v2) => v2.id === v.id);
      if (previousNeedIndex >= 0) {
        return v.amount !== previousNeeds[previousNeedIndex].amount;
      }
      return v.amount > 0;
    });
  } else {
    return categoryNeeds.some((v) => v.amount > 0);
  }
}
