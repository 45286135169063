import React from 'react';

import { useGetApplicationsQuery } from '@store/api/applications.api';

import { CustomerApplications } from './CustomerApplications';
import { CustomerPagination } from '../CustomerNavigation';
import { useCustomerApplicationsParams } from '../hooks/useCustomerApplicationsParams';
import { usePagination } from '@shared/hooks/usePagination';
import { SkeletonApplicationCard } from '@widgets/cabinet/applicationCard/SkeletonCard';

interface CabinetCustomerApplicationsProps {
  type: string;
}

export const CabinetCustomerApplications: React.FC<CabinetCustomerApplicationsProps> = ({ type }) => {
  const pagination = usePagination();
  const params = useCustomerApplicationsParams(pagination, type);

  const { data = { items: [] }, isFetching } = useGetApplicationsQuery(params);
  const { items: applications } = data;

  return (
    <div className="relative min-h-[350px] max-w-[948px]">
      {isFetching && (
        <div className="space-y-5">
          {Array.from({ length: 5 }).map((_, index) => (
            <SkeletonApplicationCard key={index} />
          ))}
        </div>
      )}
      {!isFetching && (
        <>
          <CustomerApplications items={applications} type={type} />
          <CustomerPagination pagination={pagination} type={type} />
        </>
      )}
    </div>
  );
};
