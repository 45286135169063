export const CABINET_TAB_CREATE = 'create';
export const CABINET_TAB_CURRENT = 'new';
export const CABINET_TAB_WAIT_FOR_HAND_HELP_OFFLINE = 'hand_to_hand';

export const CABINET_TAB_VERIFICATION = 'in_progress';
export const CABINET_TAB_IN_PROGRESS = 'processed'; //
export const CABINET_TAB_DONE = 'completed';

export const cabinetTabsData = [
  {
    eventKey: CABINET_TAB_CREATE,
    isActive: false,
    label: 'new-application',
    indication: null,
    indicationDisable: true,
  },
  {
    eventKey: CABINET_TAB_CURRENT,
    isActive: true,
    label: 'wait-for-help',
    indication: null,
  },
  {
    eventKey: CABINET_TAB_VERIFICATION,
    isActive: true,
    label: 'verified',
    indication: null,
  },
  {
    eventKey: CABINET_TAB_WAIT_FOR_HAND_HELP_OFFLINE,
    isActive: true,
    label: 'from-hand-to-hand',
    indication: null,
  },
  {
    eventKey: CABINET_TAB_IN_PROGRESS,
    isActive: false,
    label: 'applications-in-volunteer',
    indication: null,
  },
  {
    eventKey: CABINET_TAB_DONE,
    isActive: false,
    label: 'me-helped',
    indication: null,
  },
];
