import { useToggleState } from '@shared/hooks/useToggleState';
import { useMemo } from 'react';

interface GetModalConfirmProps {
  title: string;
  onConfirm: () => void;
}

export function useGetModalConfirmProps(props: GetModalConfirmProps) {
  const [isModalConfirmVisible, toggleModalConfirmVisibility] = useToggleState(false);
  const { title, onConfirm } = props;

  return useMemo(
    () => ({
      isVisible: isModalConfirmVisible,
      onClose: toggleModalConfirmVisibility,
      onApprove: () => {
        onConfirm();
        toggleModalConfirmVisibility();
      },
      title,
    }),
    [title, isModalConfirmVisible, toggleModalConfirmVisibility, onConfirm],
  );
}
