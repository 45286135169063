import { useCancelApplicationItemsMutation } from '@store/api/applications.api';
import { ApplicationsTotalTarget } from '@widgets/cabinet/enums';

export function useCancelCustomerApplicationItems() {
  const [cancelApplicationItems, { isLoading }] = useCancelApplicationItemsMutation();

  async function cancelItems(itemIds: number[]) {
    try {
      await cancelApplicationItems({
        target: ApplicationsTotalTarget.Customer,
        itemIds,
      }).unwrap();
    } catch (err) {
      console.log('useApplicationActions/cancelItems', err);
    }
  }

  return [cancelItems, { isLoading }] as const;
}
