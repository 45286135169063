import { useCancelCustomerApplicationItems } from '@shared/hooks/useCancelCustomerApplicationItems';
import { Loader } from '@shared/ui/loader/Loader';
import { LoaderWrapper } from '@shared/ui/loader/LoaderWrapper';
import React, { useState } from 'react';
import { ApplicationCardAddress } from './ApplicationCardAddress';
import { ApplicationCardAmount } from './ApplicationCardAmount';
import { ApplicationCardApplicantCategories } from './ApplicationCardApplicantCategories';
import { ApplicationCardCollapseController } from './ApplicationCardCollapseController';
import { ApplicationCardNeedsHolder } from './ApplicationCardNeedsHolder';
import { DenyApplicationButton } from './DenyApplicationButton';
import { ApplicationCardProps } from './types';
import { ApproveApplicationButton } from './ApproveApplicationButton';
import { useApproveApplicationItemsMutation } from '@store/api/applications.api';
import { CustomerHandToHandApplicationCardItems } from './CustomerHandToHandApplicationCardItems';
import { useRemoveHandToHandItemsHandler } from './useRemoveHandToHandHandler';
import { CustomerHandToHandChangeDate } from './CustomerHandToHandChangeDate';
import { ApplicationState } from '@shared/enums';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

const CustomerApplicationEndDateView = ({ date }: { date: string }) => {
  const { t } = useTranslation();

  if (!date) {
    return null;
  }

  return (
    <div className="pt-4">
      <span className="text-black-50 text-xxs mr-2">{t('end-date')}</span>
      <span>{format(new Date(date), 'dd.MM.yyyy')}</span>
    </div>
  );
};

const CustomerHandToHandTip = () => {
  const { t } = useTranslation();

  return <div className="text-black-50 text-xxs mt-4 text-center">{t('user-in-need-confirm-after-receiving')}</div>;
};

const useApproveCustomerApplicationItems = () => {
  const [approveHandler] = useApproveApplicationItemsMutation();
  const [isLoading, setIsLoading] = useState(false);

  const onApprove = async (dto: number[]) => {
    try {
      setIsLoading(true);
      await approveHandler({ items: dto.map((id) => ({ id })) }).unwrap();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return [onApprove, { isLoading }] as const;
};

type CustomerHandToHandApplicationCardProps = ApplicationCardProps & {
  loading?: boolean;
};

const VolunteerSection = () => {
  return null;
};

function useIsCollapsed() {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const toggleCollapsedHandler = () => setIsCollapsed((isCollapsed) => !isCollapsed);

  return { isCollapsed, toggleCollapsedHandler } as const;
}

function usePerformCardState(props: CustomerHandToHandApplicationCardProps, actions: any) {
  const { isCollapsed, toggleCollapsedHandler } = useIsCollapsed();

  const canChangeInProgressTo =
    props.items.every((item) => item.state === ApplicationState.Processed) &&
    !!props.inProgressAt &&
    !!props.inProgressTo;

  return {
    applicationId: props.id,
    purchase: props.purchase,
    view: props.view,
    volunteer: props.volunteer,
    applicationCardAddressProps: {
      address: props.customer.address,
    },
    applicationCardAmountProps: {
      amount: props.items.length,
      label: 'total-needs',
    },
    applicationCardItemsProps: {
      items: props.items,
      view: props.view,
    },
    applicationCardWhoNeedsProps: {
      customer: props.customer,
      view: props.view,
    },
    collapsedProps: {
      isCollapsed,
      toggleCollapsedHandler,
    },
    approveButtonProps: {
      volunteer: props.volunteer,
      needConfirm: true,
      onClick: () => actions.approveAppItems(props.items.map((item) => item.id)),
    },
    denyButtonProps: {
      needConfirm: true,
      onClick: () => actions.onRemove(props.items.map((item) => item.id)),
    },
    inProgressTo: props.inProgressTo,
  } as const;
}

export const CustomerHandToHandApplicationCard = (props: CustomerHandToHandApplicationCardProps) => {
  const [approveAppItems, { isLoading }] = useApproveCustomerApplicationItems();

  const onRemove = useRemoveHandToHandItemsHandler();

  const {
    view,
    approveButtonProps,
    applicationCardAddressProps,
    applicationCardAmountProps,
    applicationCardItemsProps,
    applicationCardWhoNeedsProps,
    denyButtonProps,
    collapsedProps,
    inProgressTo,
  } = usePerformCardState(props, { approveAppItems, onRemove });

  return (
    <div className="relative p-[24px] lg:p-[32px] bg-white border-hawkes-blue border-2 rounded-lg">
      <VolunteerSection />
      {isLoading && (
        <LoaderWrapper className="rounded-lg">
          <Loader className="h-32" />
        </LoaderWrapper>
      )}
      <div className="flex items-start justify-between w-100">
        <ApplicationCardAddress {...applicationCardAddressProps} className="mb-4" />
        <ApplicationCardCollapseController
          className="hidden lg:flex"
          onClick={collapsedProps.toggleCollapsedHandler}
          isCollapsed={collapsedProps.isCollapsed}
          view={view}
        />
      </div>
      <div className="flex flex-col md:flex-row md:justify-between">
        <ApplicationCardAmount {...applicationCardAmountProps} />
        <div>
          <ApproveApplicationButton className="my-3 mx-auto md:my-0 md:mx-0" {...approveButtonProps} />
          <DenyApplicationButton className="mt-2 lg:ml-auto mx-auto lg:mr-0" {...denyButtonProps} />
        </div>
      </div>
      <div className="flex justify-center">
        <ApplicationCardCollapseController
          className="flex mx-auto lg:hidden"
          onClick={collapsedProps.toggleCollapsedHandler}
          isCollapsed={collapsedProps.isCollapsed}
          view={view}
        />
      </div>
      <ApplicationCardNeedsHolder isCollapsed={collapsedProps.isCollapsed}>
        <CustomerHandToHandApplicationCardItems {...applicationCardItemsProps} />
        <ApplicationCardApplicantCategories {...applicationCardWhoNeedsProps} />
      </ApplicationCardNeedsHolder>
      <CustomerApplicationEndDateView date={inProgressTo} />
      <CustomerHandToHandTip />
    </div>
  );
};
