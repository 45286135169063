import { IconTrashBin } from '@shared/icons/IconTrashBin';
import { Button } from '@shared/ui/buttons/Button';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetModalConfirmProps } from '../volunteer/modals/hooks/useGetModalConfirmProps';
import { ModalConfirmAction } from '../volunteer/modals/ModalConfirmAction';
import { ApplicationCardProps } from './types';

type CancelCertificateButtonWidget = {
  purchase: ApplicationCardProps['purchase'];
  onClick: () => void;
  className?: string;
  needConfirm?: boolean;
};

function useComponentState(props: CancelCertificateButtonWidget) {
  const { needConfirm, onClick } = props;
  const { t } = useTranslation();
  const modalConfirmProps = useGetModalConfirmProps({
    title: 'revoked-this-certificate',
    onConfirm: onClick,
  });

  const handleButtonClick = useCallback(() => {
    if (needConfirm) {
      modalConfirmProps.onClose();
      return;
    }
    onClick();
  }, [needConfirm]);

  return {
    ...props,
    t,
    modalConfirmProps,
    handleButtonClick,
  };
}

export const CancelCertificateButtonWidget: React.FC<CancelCertificateButtonWidget> = (props) => {
  const { t, needConfirm, modalConfirmProps, className, handleButtonClick } = useComponentState(props);

  return (
    <>
      <Button
        as="button"
        variant="secondary"
        onClick={handleButtonClick}
        icon={IconTrashBin}
        iconClassName="mr-3"
        className={clsx('flex items-center', className)}
      >
        {t('revoked-this-certificate')}
      </Button>
      {needConfirm && <ModalConfirmAction {...modalConfirmProps} />}
    </>
  );
};
