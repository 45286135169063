import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Element } from '@shared/interfaces/element.interface';

import { HowToHelpType, PurchaseStoreType } from '@shared/enums';
import { PrimaryDropdown } from '@shared/dropdowns/PrimaryDropdown';

import { useIntegrationConfigs } from '@hooks/useIntegrationConfigs';

interface Props extends Element {
  value: string;
  onChange: (value: string) => void;
  errorText?: string;
}

function useComponentState() {
  const { t } = useTranslation();
  const integrationConfigs = useIntegrationConfigs();
  const pharmacyShops =
    integrationConfigs.find((config) => config.type === HowToHelpType.OnlineMedicaments)?.shops || [];

  const pharmacyOptions = useMemo(
    () =>
      pharmacyShops.map((item) => ({
        value: item.name,
        label: t(item.label),
      })),
    // .concat({ value: PurchaseStoreType.Default, label: t('pharmacies-missing') }),
    [pharmacyShops, t],
  );

  return {
    t,
    pharmacyOptions,
  };
}

export const PharmacyDropdown: React.FC<Props> = ({ value, className, errorText, onChange }) => {
  const { t, pharmacyOptions } = useComponentState();

  return (
    <PrimaryDropdown
      value={value}
      onChange={onChange}
      options={pharmacyOptions}
      className={className}
      label={t('choose-pharmacy')}
      errorText={errorText && t(errorText)}
    />
  );
};
