import { useCallback, useState } from 'react';

export interface UsePagination {
  initialPage: number;
  page: number;
  perPage: number;
  onPageChange: (data: { selected: number }) => void;
  reset: () => void;
}

export function usePagination() {
  // TODO: connect with query string
  const [page, setPage] = useState<number>(1);

  return {
    initialPage: page - 1,
    page,
    perPage: 10,
    onPageChange: useCallback(
      (data: { selected: number }) => {
        setPage(data.selected + 1);
      },
      [setPage],
    ),
    reset: useCallback(() => setPage(1), [setPage]),
  };
}
