import React from 'react';
import { ApplicationCardRemovableItem } from './ApplicationCardRemovableItem';
import { ApplicationItem } from './types';
import { ApplicationView } from '@shared/enums';
import { isDefaultView } from '@shared/utils/applications';
import { ApplicationCardItemDetails } from './ApplicationCardItemDetails';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  items: ApplicationItem[];
  view: ApplicationView;
  onRemove: (id: number) => void;
};

function useCustomerCurrentApplicationCardItemsState({ view, items, onRemove }: Props) {
  return { view, items, onRemove } as const;
}

export const CustomerCurrentApplicationCardItems = (props: Props) => {
  const { view, items, onRemove } = useCustomerCurrentApplicationCardItemsState(props);
  const ApplicationCardItem = isDefaultView(view) ? ApplicationCardRemovableItem : ApplicationCardItemDetails;

  return (
    <div className="lg:col-span-3">
      {items.map((item) => (
        <ApplicationCardItem onRemove={onRemove} key={item.id} needConfirm {...item} />
      ))}
    </div>
  );
};

type CustomerCurrentApplicationViewCardItemsProps = React.HTMLAttributes<HTMLDivElement> & {
  items: ApplicationItem[];
};

export const CustomerCurrentApplicationViewCardItems = (props: CustomerCurrentApplicationViewCardItemsProps) => {
  const { items } = props;
  console.log('items', items);

  return (
    <div className="lg:col-span-3">
      {items.map((item) => (
        <ApplicationCardItemDetails key={item.id} {...item} />
      ))}
    </div>
  );
};
