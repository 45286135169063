import React from 'react';

interface Props {
  className?: string;
  onClick: () => void;
}

export const IconTrashBin: React.FC<Props> = ({ className = '', onClick }) => (
  <svg
    className={className}
    onClick={onClick}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2 4H3.55556H16" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M5.57143 4.2V2.6C5.57143 2.17565 5.75204 1.76869 6.07353 1.46863C6.39502 1.16857 6.83106 1 7.28571 1H10.7143C11.1689 1 11.605 1.16857 11.9265 1.46863C12.248 1.76869 12.4286 2.17565 12.4286 2.6V4.2M15 4.2V15.4C15 15.8243 14.8194 16.2313 14.4979 16.5314C14.1764 16.8314 13.7404 17 13.2857 17H4.71429C4.25963 17 3.82359 16.8314 3.5021 16.5314C3.18061 16.2313 3 15.8243 3 15.4V4.2H15Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M7 8V13" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11 8V13" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
