import useMaxWidthMediaQuery from '@hooks/useMaxWidthMediaQuery';
import { Button } from '@shared/ui/buttons/Button';
import { DropdownCheckboxListWithSearch } from '@shared/ui/dropdowns/dropdown-checkbox-list-with-search/DropdownCheckboxListWithSearch';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCategoryNeeds } from './hooks/useCategoryNeeds';
import { useGetCategoryNeedsSubmitButtonProps } from './hooks/useGetCategoryNeedsSubmitButtonProps';

function useComponentState() {
  const [isDropdownMenuVisible, setDropdownMenuVisibility] = useState(false);
  const isSmallScreen = useMaxWidthMediaQuery('md');
  const {
    inputValue,
    categoryNeeds,
    categoryNeedsFiltered,
    handleChangeNeedAmount,
    handleChangeInputValue,
    handleClearInputValue,
  } = useCategoryNeeds();
  const { t } = useTranslation();
  const submitButtonProps = useGetCategoryNeedsSubmitButtonProps(categoryNeeds, handleClearInputValue);
  const isMobileAndDropdownVisible = isSmallScreen && isDropdownMenuVisible;
  const buttonSize = isSmallScreen ? 'large' : 'small';

  const dropdownListWithAmountsProps = {
    inputValue,
    list: categoryNeedsFiltered,
    isDropdownMenuVisible,
    onChangeAmount: handleChangeNeedAmount,
    onChangeFieldValue: handleChangeInputValue,
    onClearInputValue: handleClearInputValue,
    setDropdownMenuVisibility,
  };

  const buttonWrapperCn = clsx(
    'flex justify-center self-center md:self-auto md:justify-start',
    isMobileAndDropdownVisible && 'fixed left-1/2 bottom-6 -translate-x-1/2 z-10',
  );
  const buttonCn = clsx('button-add-needs', isMobileAndDropdownVisible && 'shadow-button-overlay');

  return {
    t,
    buttonWrapperCn,
    buttonCn,
    dropdownListWithAmountsProps,
    submitButtonProps,
    buttonSize,
  };
}

export const ChooseCategoryNeeds = () => {
  const { t, buttonSize, buttonWrapperCn, buttonCn, dropdownListWithAmountsProps, submitButtonProps } =
    useComponentState();

  return (
    <div className="flex flex-col md:flex-row md:items-center mt-4 md:mt-8 pb-6">
      <DropdownCheckboxListWithSearch {...dropdownListWithAmountsProps} />
      <div className={buttonWrapperCn}>
        <Button id="button-add-needs" as="button" size={buttonSize} className={buttonCn} {...submitButtonProps}>
          {t('add')}
        </Button>
      </div>
    </div>
  );
};
