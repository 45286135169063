import { useLanguage } from '@hooks/useLanguage';
import { useShops } from '@hooks/useShops';
import { HowToHelpType } from '@shared/enums';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import {
  selectEasterShop,
  selectEasterRules,
  selectChildProtectionRules,
  selectChildProtectionShop,
} from '@store/slices/customer/selectors';
import { customerActions } from '@store/slices/customer/slice';
import React, { useCallback, useMemo } from 'react';
import { CustomerStoreRulesCheckbox } from './CustomerStoreRulesCheckbox';

import { getRulesUrl } from './utils';
import { useChildProtectionRulesCheckboxVisibility } from '../../hooks/useChildProtectionRulesCheckboxVisibility';

function useComponentState() {
  const language = useLanguage();
  const dispatch = useAppDispatch();
  const childProtectionShop = useAppSelector(selectChildProtectionShop);
  const childProtectionShopChecked = useAppSelector(selectChildProtectionRules);

  const shops = useShops(HowToHelpType.Easter);
  const selectedShop = shops.find((shop) => shop.name === childProtectionShop);

  const isRulesCheckboxVisible = useChildProtectionRulesCheckboxVisibility();
  const rulesLink = useMemo(() => {
    if (isRulesCheckboxVisible) {
      return getRulesUrl(selectedShop?.config.rules, language);
    }
  }, [isRulesCheckboxVisible, selectedShop, language]);

  const storeName = useMemo(() => {
    if (isRulesCheckboxVisible) {
      return selectedShop?.label;
    }
  }, [isRulesCheckboxVisible, selectedShop]);

  const handleCheckboxChange = useCallback((e) => {
    dispatch(customerActions.changeChildProtectedRules(e.target.checked));
  }, []);

  const customerStoreRulesCheckboxProps = {
    name: 'child-protection',
    checked: childProtectionShopChecked,
    rulesLink,
    storeName,
    onChange: handleCheckboxChange,
    className: 'first:mt-0 mt-3',
  };

  return {
    isRulesCheckboxVisible,
    customerStoreRulesCheckboxProps,
  };
}

export const CustomerChildProtectionRulesCheckbox = () => {
  const { isRulesCheckboxVisible, customerStoreRulesCheckboxProps } = useComponentState();

  if (!isRulesCheckboxVisible) return null;

  return <CustomerStoreRulesCheckbox {...customerStoreRulesCheckboxProps} />;
};
